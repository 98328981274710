import React from 'react';
import { Chorale } from './Chorale';
import './App.scss';

function App() {
  return (
    <div className="App">
      <Chorale></Chorale>
    </div>
  );
}

export default App;
