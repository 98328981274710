import { Note } from "@tonaljs/tonal";
import {RealKey} from './RealKey'

//you can force it to quantize to key by setting alt to 0 


export class RealNote {
    octave! : number;
    letter!: string;

    alt! : number; //number of alterations off from key signature
    explicitlyDisplayAccidentals: boolean = false; //the user's choice

    duration: number;
    position: number;
    measure: number;
    voice: number;

    private key: RealKey;
    isNonChordTone: boolean = false;
    dotted: boolean = false; 

    constructor(pitch: string, 
                duration: number, 
                position: number, 
                measure: number, 
                voice: number, 
                isNonChordTone: boolean,
                dotted: boolean,
                key: RealKey) {
      this.key = key
     
      this.setPitch(pitch)
      
      this.duration = duration
      this.position = position
      this.measure = measure
      this.voice = voice
      this.isNonChordTone = isNonChordTone
      this.dotted = dotted
    }

    getKey() {
      return this.key
    }
    
    setPitch(pitch: string) {
      this.octave = Note.get(pitch).oct!
      this.letter = Note.get(pitch).letter!

      let noteInKS = this.key.letters.includes(this.letter)
      let accidentalsInKS = !noteInKS ? 0 : //if note not in key signature, natural
                            (
                              this.key.sig.charAt(0) == "#" ? 1 ://if sharp, diatonic note is +1
                                                              -1 //otherwise, it's flat (-1)
                            )
      let accidentalsInNote = Note.get(pitch).acc.length * (Note.get(pitch).acc.charAt(0) == "#" ? 1 : -1)
      this.alt = accidentalsInNote - accidentalsInKS
    }

    getAccidentals() : string {
      let noteInKS = this.key.letters.includes(this.letter)
      let accidentalsInKS = !noteInKS ? 0 : //if note not in key signature, natural
      (
        this.key.sig.charAt(0) == "#" ? 1 ://if sharp, diatonic note is +1
                                        -1 //otherwise, it's flat (-1)
      )
      let absoluteAcc = accidentalsInKS + this.alt
      let sym = absoluteAcc == 0 ? "" : (
                absoluteAcc < 0 ? "b" :
                                  "#"
                )
      return sym.repeat(Math.abs(absoluteAcc))
    }

    getPitch() : string {
      return this.letter + this.getAccidentals() + this.octave
    }

    getStart() : number {
      return this.position
    }

    getEnd() : number {
      return this.position + this.getDuration()
    }

    setExplicitlyDisplayAccidentals(val : boolean) {
      this.explicitlyDisplayAccidentals = val
    }
    getDuration() : number {
      return this.duration + (this.dotted ? this.duration / 2 : 0)
    }
    getBaseDuration() : number {
      return this.duration
    }
    setDuration(time: number) {
      this.duration = time
    }
  }
  