import {RealNote} from './RealNote'
import {RealKey} from './RealKey'
import { Note, TimeSignature, Key } from "@tonaljs/tonal";
import { Config } from '../Util'
import ImmutableChorale from './Engine';

var _ = require('lodash');

export interface VoiceRange {
    low: string,
    hi : string
}


export class Voice {
    id: number
    notes: RealNote[]
    range: VoiceRange
    timeSignature : string 
    key: RealKey;
    private engineObject: ImmutableChorale
    constructor(id: number, notes: RealNote[], timeSignature : string, range: VoiceRange, key: RealKey, engineObject: ImmutableChorale) {
      this.id = id
      this.engineObject = engineObject
      this.key = key
      this.notes = notes
      this.timeSignature = timeSignature
      this.range = range
      Object.freeze(this)
    }

    sorted() {
      return this.notes.slice().sort((a, b) => a.position - b.position)
    }

    measures() {
      return this.notes.sort((b,a) => {
           return a.measure - b.measure
      })[0].measure + 1
  }
  
    verifyRange(position: number) {
        if(!this.index(position)) return this

        let note = this.index(position);

        let height = Note.get(note?.getPitch()!).height
        let low = Note.get(this.range.low).height
        let high = Note.get(this.range.hi).height


        if(height && high && height > high) {
            return this.changePitch(position, this.range.hi)
        } else if (note!.octave <= 0 || (height && low && height < low)) {
            return this.changePitch(position, this.range.low)
        }
        return this
    }
  
    //an array-like syntax, but for getting notes at any position
    index(position: number) {
      for(var note of this.notes) {
        if(position >= note.getStart() && position < note.getEnd()) {
            return note
        }
      } return undefined
    }

    //an array-like syntax, but for getting notes at any position
    indexRange(start: number, end: number) {
      return this.notes.filter((note) => {
        return start >= note.getStart() && end < note.getEnd()
      })
    }

  
    changePitch(position: number, value: string) : Voice {
      if(this.index(position) && this.index(position)!.getPitch() !== value) {
        let tonalNote = Note.get(value)
        if(!tonalNote)
          return this;
        this.index(position)!.setPitch(value)
        return this.verifyRange(position);
      } else return this
    } 
  
    setNoteAccAtPosition(position: number, delta: number) { // delta = 0, set natural
      var x = this
      if(delta != 0) {
        x.index(position)!.alt += delta
      } else {
          let noteInKS = this.key.letters.includes(x.index(position)!.letter)
          let accidentalsInKS = !noteInKS ? 0 : //if note not in key signature, natural
          (
            this.key.sig.charAt(0) == "#" ? 1 ://if sharp, diatonic note is +1
                                            -1 //otherwise, it's flat (-1)
          )
          let natural = accidentalsInKS * -1
          x.index(position)!.alt = natural
      }

      while(x.index(position)!.getAccidentals().length > 2) { //only allow up to two accidentals
        x.index(position)!.alt += -1 * Math.sign(x.index(position)!.alt)
      }

      return x
    }

    toggleNonChordTone(position: number) {  
      if(this.index(position)) {
        this.index(position)!.isNonChordTone = !this.index(position)!.isNonChordTone
      }
      return this;
    }
    toggleDotted(position: number) {  
      if(this.index(position)) {
        this.index(position)!.dotted = !this.index(position)!.dotted
      }
      return this.updatePositions();
    }
    toggleExplicitlyDisplayAccidentals(position: number) {
      var x = this
      if(x.index(position))
          x.index(position)!.explicitlyDisplayAccidentals = !x.index(position)?.explicitlyDisplayAccidentals
      return this;
    }
    
    removeNote(position: number) : Voice {
      if(!this.index(position)) return this
      
      let newVoice = new Voice(this.id, this.notes.filter((v) => {
        return v.position != position
      }), this.timeSignature, this.range, this.key,  this.engineObject)   
      return newVoice.updatePositions();
    }

    changeDuration(position: number, value: number) {
      if(this.index(position) && (this.index(position)!.getDuration() !== value)) {     
        this.index(position)!.setDuration(value);

        
        let ts = TimeSignature.get(this.timeSignature)
        let division = ts.upper! as number/ts.lower!

        this.index(position)!.measure = Math.floor(position / division)

        return this.updatePositions()
      } else return this
    } 
    
    updatePositions() {
      this.notes.sort((a,b) => {
        return a.position - b.position
      })

      let newPosition = 0
      let index = 0
      while(index < this.notes.length) {
        let ts = TimeSignature.get(this.timeSignature)
        let division = ts.upper! as number/ts.lower!

        this.notes[index].position = newPosition
        this.notes[index].measure = Math.floor(newPosition / division)
        newPosition += this.notes[index].getDuration()
        index++;
      }
      return this
    }
    seekPosition(index: number) {
      return this.getEngine().seekPosition(index)
    }
    getEngine() {
      return this.engineObject
    }
    //return a range
  
  }