
import {NCT} from '../engine/Engine'
import { useState } from 'react'; // let's also import Component

export interface NCTProps {
    nct: NCT,
    offset: number
}
export default function NCTRenderer({nct, offset}: NCTProps) {

    return <div className="nct" style={{left: offset}}>{NCT[nct]}</div>
}
