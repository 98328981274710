import { Note } from "@tonaljs/tonal";

export const Config = {
   leftOffset : 80,
   bedHeight : 10,
   qNSpacing : 320,
   ksSpread : 1/15,
   initialScale : 1.0,

   staves : 2,
   clefs : ["𝄞","𝄢"],
   startingNotes : ["E4", "G2"],
   linesPerStaff : 5,
   voicesPerStaff : 2,
   measureSpacing: 25, 
   voiceLabels : [
    "B","T","A","S"
   ],

   maxLeap: 3,
   noteStiffness: 0,
   noteDamping: 1,
   allLetters : ["C", "D", "E", "F", "G", "A", "B", "C"]
}

let bedsPerStaff = (Config.linesPerStaff*2)-1;

export const endingNotes = Config.startingNotes.map((a) => {
   return incrementNote(a, bedsPerStaff-1)
})


//https://stackoverflow.com/questions/2256607/how-to-get-the-next-letter-of-the-alphabet-in-javascript
export function incrementNote(n : string, letters: number){
   if(letters < 0) 
       return decrementNote(n, -1 * letters)
   let o = parseInt(n.substring(n.length-1,n.length)) + Math.floor((Config.allLetters.indexOf(n.charAt(0)) + letters) / 7)
   return Config.allLetters[(Config.allLetters.indexOf(n.charAt(0)) + letters) % 7] + o
}

// G2 //3 
export function decrementNote(n: string, letters: number) {
   let distFromC = Config.allLetters.indexOf(n.charAt(0))
   let octs = Math.ceil((letters - distFromC) / 7)

   let changeForward = 7-(letters % 7)
   
   let startOct = parseInt(n.substring(n.length-1,n.length)) 

   return Config.allLetters[(Config.allLetters.indexOf(n.charAt(0)) + changeForward) % 7] + (startOct - octs)
}

export function calculateLedgerLines(letter: string, oct: number, staff: number) : number {
   if(bedNumberForNote(letter, oct, staff) !== -1) return 0;

   let startingOct = parseInt(Config.startingNotes[staff].charAt(1))
   let endingOct = parseInt(endingNotes[staff].charAt(1))

   if(Note.get(letter+oct).height! > Note.get(endingNotes[staff]).height!) {// if note goes over the line
       let numberOffset = Config.allLetters.indexOf(letter) - Config.allLetters.indexOf(endingNotes[staff].charAt(0))
       return numberOffset + (oct - endingOct)*7
   }
   else {
       let numberOffset = Config.allLetters.indexOf(Config.startingNotes[staff].charAt(0)) - Config.allLetters.indexOf(letter)
       return -1 * numberOffset + (oct - startingOct)*7
   }
}

export function calculateDistance(aPitch: string, bPitch: string) : number {
   let numberOffset = Config.allLetters.indexOf(aPitch.charAt(0)) - Config.allLetters.indexOf(bPitch.charAt(0))
   return numberOffset + (parseInt(aPitch.charAt(aPitch.length-1)) - parseInt(bPitch.charAt(bPitch.length-1)))*7
}

export function bedNumberForNote(letter: string, oct: number, staff: number) : number {
   let endingOct = parseInt(endingNotes[staff].charAt(1)) 
   let numberOffset = Config.allLetters.indexOf(endingNotes[staff].charAt(0)) - Config.allLetters.indexOf(letter)
   
   if(Note.get(letter+oct).height! > Note.get(endingNotes[staff]).height!) return -1;
   if(Note.get(letter+oct).height! < Note.get(Config.startingNotes[staff]).height!) return -1;

   return staff*(bedsPerStaff) + numberOffset + (endingOct - oct)*7
}

export function convertAccidentals(accidentals : string) : string {
    if(!accidentals)
        return ""
    if(accidentals.lastIndexOf("#") != accidentals.indexOf("#")) {
        accidentals = accidentals.replaceAll("##","𝄪")
    }
    if(accidentals.lastIndexOf("b") != accidentals.indexOf("b")) {
        accidentals = accidentals.replaceAll("bb","𝄫")
    }
   return accidentals.charAt(0) === "#" ? accidentals.replaceAll("#","♯") : accidentals.replaceAll("b" ,"♭")
}

export const convertTimeSignatureChar = (num: number) => {
   switch(num){
       case 0:
           return ""
       case 1:
           return ""
       case 2:
           return ""
       case 3:
           return ""
       case 4:
           return ""
       case 5:
           return ""
       case 6:
           return ""
       case 7:
           return ""
       case 8:
           return ""
       case 9:
           return ""
   }
}
