import { Config, convertAccidentals } from "../Util"
import {RealNote, NCT} from '../engine/Engine'
import {ReactElement} from "react"
import NCTRenderer from "./NCTRenderer"

export default class RendererNote {
        note: RealNote
        documentX: number
        marginX: number

        onMouseDown : (position: number, voice: number, y: number) => void
        isRest: boolean
        selected : boolean
        temporary : boolean
        stemDirectionUp : boolean
        ledgerLinesRequired: number
        crowd: number
        doBeam: boolean
        beam: number
        beamEnd: boolean

        altNew : boolean // if the alteration is new info
        nctType: NCT
        nextNoteCrowd = 0

        constructor(
            note: RealNote,
            documentX: number,
            marginX: number,
            onMouseDown: (position: number, voice: number, y: number) => void,
            stemDirectionUp = false,
            ledgerLinesRequired = 0,
            isRest = false,
            selected = false,
            temporary = false,
            crowd = 0,
            beam = 0,
            doBeam = false,
            beamEnd = false,
            altNew = false,
            nctType: NCT,
            nextNoteCrowd: number
            ) {
                
            this.note = note
            this.documentX = documentX
            this.marginX = marginX
            this.onMouseDown = onMouseDown
            this.isRest = isRest
            this.selected = selected
            this.temporary = temporary
            this.stemDirectionUp = stemDirectionUp
            this.ledgerLinesRequired = ledgerLinesRequired
            this.crowd = crowd
            this.beam = beam
            this.doBeam = doBeam
            this.beamEnd = beamEnd
            this.altNew = altNew
            this.nctType = nctType
            this.nextNoteCrowd = nextNoteCrowd
          }

        getSymbol() {
            switch(this.note.duration){ //TODO more
                case 1/8:
                    if(this.doBeam|| this.beamEnd) {
                        return "𝅘𝅥"
                    }
                    return "𝅘𝅥𝅮"
                case 1/4:
                    return "𝅘𝅥"
                case 1/2:
                    return "𝅗𝅥"
                case 1:
                    return "𝅝"
            }
        }
        getSig() {
            return this.note.getKey().sig
          }
          getKeyLetters() {
            return this.note.getKey().letters
          }

        getElement(scale: number, verticalOffset: number) : ReactElement {
                let docuPos = this.documentX*scale + this.marginX
                let style : React.CSSProperties = {left: docuPos, zIndex: Config.voicesPerStaff*Config.staves - this.note.voice}
                let beamAngle = 0
                let beamSide = Config.qNSpacing * 1/8 * scale
                var beamWidth = beamSide
                let beamHeight = this.beam*Config.bedHeight

                let beamOffset = Math.floor(docuPos) + (this.stemDirectionUp ? 60 : 79)
                
                let acc = this.note.getAccidentals()
                let showAccidentals =   
                    this.note.explicitlyDisplayAccidentals ||
                    this.note.alt != 0 || this.altNew
                
                if(acc == "" && (this.note.explicitlyDisplayAccidentals || this.altNew)) {
                    acc="♮"
                }

                if(this.doBeam && this.beam !== 0) {
                    beamAngle = (Math.atan(beamHeight/beamSide)*180)/Math.PI
                    beamWidth = Math.sqrt(beamSide*beamSide + beamHeight*beamHeight);
                } else if(this.doBeam && this.beam === 0) {
                    beamHeight = Config.bedHeight
                }

                if(this.ledgerLinesRequired !== 0) {
                    style.top = Config.bedHeight*(-1*this.ledgerLinesRequired)-50 + "px"
                }
                //crowding beams
                if(this.crowd < 0) {
                    beamOffset += 5;
                    beamSide -= 5;
                } else if(this.crowd > 0) {
                    beamOffset -= 5;
                    beamSide += 5;
                }
                
                if(this.nextNoteCrowd > 0) {
                    beamSide -= 5;
                } else if(this.nextNoteCrowd < 0) {
                    beamSide += 5;
                }
                

                return <><div
                key={this.note.position+"-"+this.note.voice}
                className={"note mx-5 px-2 user-select-none"+
                (this.stemDirectionUp ? " note-flipped" : "")+
                (this.selected ? " note-sel" : "")+
                (this.doBeam || this.beamEnd ? " note-beamed" : "") +
                (" dur-" + this.note.duration*8)+
                (this.crowd !== 0 ? (
                    this.crowd < 0 ? " note-crowd-l" : " note-crowd-r"
                ) : "")} 
                    style={style}
                    id={this.note.position+this.note.getPitch()+this.note.octave}
                    onMouseDown={(e) => { e.preventDefault(); this.onMouseDown(this.note.position, this.note.voice, e.pageY) }}
                    onTouchStart={(e) => { e.preventDefault(); this.onMouseDown(this.note.position, this.note.voice, e.touches[0].pageY) }}
                    >
                        {(this.note.dotted && this.stemDirectionUp ? <span className="dot-down"> 𝅭</span> : "")}
                        {this.getSymbol()!}
                        
                        {(this.note.dotted && !this.stemDirectionUp ? <span className="dot-up"> 𝅭</span> : "")}

                        
                        </div>{showAccidentals && <div className={"accidental mx-5"+
                    (this.crowd !== 0 ? (
                        this.crowd < 0 ? " note-crowd-l" : " note-crowd-r"
                    ) : "")} style={{left: docuPos - (convertAccidentals(acc).length > 1 && convertAccidentals(acc).indexOf("𝄪") == -1 ? 12 : 2), top: this.ledgerLinesRequired ? 
                         ( this.stemDirectionUp ? Config.bedHeight*(-1*this.ledgerLinesRequired) - 40: Config.bedHeight*(-1*this.ledgerLinesRequired)+20 
                         ) : 0}}>{convertAccidentals(acc)}</div>}
                         {this.doBeam && 
                         <div className={"beam-wrapper" + 
                         (Math.abs(this.beam) === 0 ? " beam-flat" : "") +
                         (Math.abs(this.crowd) === 0 ? " x" : " beam-wrapper-crowd") + 
                         (!this.stemDirectionUp ? " beam-wrapper-top" : " beam-wrapper-bottom")} 
                         style={{width: beamSide+2, 
                            left: beamOffset,
                            transform: "translateY(" + (this.ledgerLinesRequired ? 
                            ( this.stemDirectionUp ? Config.bedHeight*(-1*this.ledgerLinesRequired) + (this.ledgerLinesRequired > 0 ? 20 : -40): 
                                                     Config.bedHeight*(-1*this.ledgerLinesRequired)+20 
                            ) : 0 ) + "px)",
                            marginTop: (beamAngle<0 ? beamHeight*1.5 : 0)}}><div style={{height: Math.abs(beamHeight)*2}}><div className={"beam " + (!this.stemDirectionUp ? "beam-top" : "beam-bottom")} style={{width: Math.ceil(beamWidth+6), transform: "rotate(" + beamAngle +"deg)", marginTop: + (beamAngle<0 ? -1*beamHeight*1.5 : "")}}></div></div></div>}

                            {this.note.isNonChordTone && 
                                <NCTRenderer nct={this.nctType} offset={docuPos}></NCTRenderer>}
                         </>
        }
}