import { Interval } from "@tonaljs/tonal";
import { RealNote, Voice } from "./Engine";
import { faBomb, faWaveSquare } from "@fortawesome/free-solid-svg-icons";

export default class AudioManager {
  private static instance : AudioManager;

  private bpm = 90;

  public static getInstance() : AudioManager {
    if(!AudioManager.instance) {
        AudioManager.instance = new AudioManager();
    }
    return AudioManager.instance;
  }
  public instruments = [
  {
    name: "808",
    note: "G3",
    file: "808_g3.wav",
    icon: "⏺️"
  },{
  name: "Electric Bass",
  note: "C3",
  file: "bass_c3.wav",
  icon: "🎸"
  },
  {
    name: "Square Wave",
    note: "C4",
    file: "synth_c3.wav",
    icon: "⎍",
    iconType: "fa",
    faIcon: faWaveSquare
  },
  {
    name: "Horn",
    note: "C4",
    file: "horn_c4.wav",
    icon: "🎺"
  },
  {
    name: "Piano",
    note: "C4",
    file: "piano_c4.wav",
    icon: "🎹"
  },
  {
    name: "Violin",
    note: "C4",
    file: "vio_c4.wav",
    icon: "🎻"
  },
  {
    name: "Flute",
    note: "C6",
    file: "flute_c6.wav",
    icon: "🥖"
  },
  {
    name: "hi",
    note: "C6",
    file: "strings_c6.wav",
    icon: "🎶"
  }]
  private soundsForVoice = [1,3,5,7]
  private audioStartTime = -1;
  private storedTime = -1;

  private audioContext = new AudioContext();

  private audioBuffers: AudioBuffer[] = []
  private positionListeners : ((pos : number) => void)[] = []
  private playingListeners : ((playing : boolean, returnPlayhead: boolean) => void)[] = []
  private meterListeners : ((volume : number[]) => void)[] = []

  private lastPosition = 0
  private lastPlaybackState = false
  private lastVolumes : number[] = []
  private isPlaying = false;

  private timers : NodeJS.Timeout[] = []
  private sources : { source: AudioBufferSourceNode, voice: number}[] = []
  private globalVol = this.audioContext.createGain()
  
  private gainNodes : GainNode[];

  private analyzers : AnalyserNode[];

  private meterLoop? : NodeJS.Timeout;

  private returnPlayhead : boolean = true;

  setReturnPlayhead(val: boolean) {
    this.returnPlayhead = val
  }

  private constructor() {
    this.instruments.map((inst, i) => {

      fetch(inst.file)
      .then(response =>  response.arrayBuffer())
      .then(buffer => this.audioContext.decodeAudioData(buffer))
      .then(audioData => {
        this.audioBuffers[i] = audioData
      })
      .catch((err) => {
        console.log(err)
      })
    })

    this.analyzers = [this.audioContext.createAnalyser(),this.audioContext.createAnalyser(),this.audioContext.createAnalyser(),this.audioContext.createAnalyser()]

    this.gainNodes = [this.audioContext.createGain(),this.audioContext.createGain(),this.audioContext.createGain(),this.audioContext.createGain()]
    this.gainNodes.map((x,i) => {
      x.connect(this.analyzers[i])
      this.analyzers[i].connect(this.globalVol)
    })

    this.globalVol.gain.value = .8

    let dyn = this.audioContext.createDynamicsCompressor()
    dyn.attack.value = 0
    dyn.release.value = 20
    
    this.globalVol.connect(dyn)
    dyn.connect(this.audioContext.destination)
}
setSoundForVoice(voice: number, value: number) {
  this.soundsForVoice[voice] = value
}
setGain(voice: number, value: number) {
  this.gainNodes[voice].gain.value = Math.round(Math.pow(value,2 )*10)/10
}
playNote(note: RealNote, voice: number, dur: number, offset: number) {
    let beatDurSec = 60 / this.bpm 
    let delay = (note.position- offset) * beatDurSec*4
    let noteLength =  beatDurSec * note.getDuration()*4
    let semi = Interval.semitones(Interval.distance(this.instruments[this.soundsForVoice[voice]].note, note.getPitch()))

    const sampleSource = new AudioBufferSourceNode(this.audioContext, {
        buffer: this.audioBuffers[this.soundsForVoice[voice]],
        playbackRate: Math.pow(Math.pow(2, 1/12), semi ? semi : 0),
        });
    this.timers.push(setTimeout(() => {
        sampleSource.connect(this.gainNodes[voice]);
        sampleSource.start();  
        this.startMeter()
        this.sources.push({source: sampleSource, voice: voice})
        if(offset <= note.getStart())
          this.sendPositionEvent(note.position)

        let shortenNote = offset > note.getStart() ? offset - note.getStart() : 0

        if(note.getEnd() != dur) {
          this.timers.push(setTimeout(() => {
            this.stopSource(sampleSource, voice)
        }, (noteLength - shortenNote) * 1000))
        } else {
          this.timers.push(setTimeout(() => {
            this.stopSource(sampleSource, voice)
        }, (noteLength * 1000) + 300))
        }
    }, delay * 1000))
    return sampleSource;
}
startMeter = () => {
  if(!this.meterLoop)
  this.meterLoop = setInterval(() => {
    // https://jameshfisher.com/2021/01/18/measuring-audio-volume-in-javascript/
    this.sendMeterUpdate(this.analyzers.map((analyzer) => {
      const pcmData = new Float32Array(analyzer.fftSize);
        analyzer.getFloatTimeDomainData(pcmData);
        let sumSquares = 0.0;
        for (const amplitude of pcmData) { sumSquares += amplitude*amplitude; }

        return Math.sqrt(sumSquares / pcmData.length);
    }))
  }, 50)
}
stopMeter = () => {
  if(this.meterLoop)
    clearInterval(this.meterLoop)
  this.meterLoop = undefined
}
stopSource = (source: AudioBufferSourceNode, voice: number) => {
  var gainNode = this.audioContext.createGain();
  source.disconnect()
  source
  .connect(gainNode)
  gainNode.connect(this.gainNodes[voice])
  gainNode.gain.setTargetAtTime(0, this.audioContext.currentTime, 0.0225);
  this.sources = this.sources.filter(x => x.source!= source)
}
stop = () => {
  this.sources.map((source) => {
    this.stopSource(source.source, source.voice)
  })
  this.timers.map((timeout)=> {
    clearTimeout(timeout)
  })
  this.sources = []
  this.timers = []
  this.isPlaying = false
  this.sendPlaybackEvent(false)
  this.lastPosition = 0
  this.lastPlaybackState = false
  this.stopMeter()
  this.sendMeterUpdate([0,0,0,0])
  this.meterLoop = undefined
  if(this.returnPlayhead) {
    this.sendPositionEvent(0)
  }
}
play = (voices: Voice[], position: number) => {
  if(this.isPlaying) {
    this.stop()
    return
  }
  this.timers = []
  this.isPlaying = true
  this.meterLoop = undefined
  this.audioContext.resume()

  let beatDurSec = 60 / this.bpm 

  let pos = position == -1 ? 0 : position

  let notesLeft = position == -1 ? voices.map((voice)=>voice.notes) : voices.map((voice) => {
    return voice.notes.filter((x) => {
      return x.getEnd() > pos
    })
  })


  let dur = (notesLeft.map((voice) => {
    return voice.sort((a,b) => {
      return b.getEnd() - a.getEnd()
    })
  }).map((x) => x[0]).sort(((a,b) => {
    return b.getEnd() - a.getEnd()
  }))[0].getEnd() - pos)*beatDurSec*4

  this.sendPositionEvent(0)
  notesLeft.map((voice, i) => {
    voice.map((note) => {
        this.playNote(note, i, dur, pos)
    })
  })
  this.sendPlaybackEvent(true)

  this.timers.push(setTimeout(() => {
    this.stop()
    this.sendPositionEvent(0)
  }, (dur * 1001)))
  this.audioStartTime = Date.now()
  this.storedTime = 0
}

private onPause = () => {
  this.storedTime += Date.now() - this.audioStartTime
}

sendPositionEvent(newPos: number) {
    if(newPos == this.lastPosition) return
    this.lastPosition = newPos
    this.positionListeners.map((listener) => {
        listener(newPos)
    })
}

sendPlaybackEvent(newState: boolean) {
  if(newState == this.lastPlaybackState) return
  this.lastPlaybackState = newState
  this.playingListeners.map((listener) => {
      listener(newState, this.returnPlayhead)
  })
}
sendMeterUpdate(volume: number[]) {
  if(volume == this.lastVolumes) return
  this.lastVolumes = volume
  this.meterListeners.map((listener) => {
      listener(volume)
  })
}
onPositionChange(callback : (position: number) => void) {
    this.positionListeners.push(callback)
}
onPlaybackStateChange(callback : (playing: boolean, returnPlayhead: boolean) => void) {
  this.playingListeners.push(callback)
}
onMeterUpdate(callback : (vol: number[]) => void) {
  this.meterListeners.push(callback)
}
getPosition = () => {
  return this.storedTime + Date.now() - this.audioStartTime
}
getIsPlaying = () => {
  return this.isPlaying
}
}