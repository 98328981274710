
export class RawNote {
    note : string;
    tv: number;
    dotted: boolean;
    constructor(note: string, val: number, acc: string = "", dotted: boolean = false) {
        this.note = note;
        this.tv = val;
        this.dotted = dotted
    }
}