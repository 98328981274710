import { Config, incrementNote, decrementNote, convertAccidentals, calculateDistance, calculateLedgerLines, bedNumberForNote, endingNotes } from "../Util"

import { useState, useRef, KeyboardEvent, MouseEventHandler, RefObject, useMemo, useEffect, SetStateAction } from 'react'; // let's also import Component
import { Note, TimeSignature, Interval } from "@tonaljs/tonal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftRotate, faArrowRightRotate, faPrint, faPlus, faMinus, faFile, faCheck, faArrowsLeftRightToLine, faArrowLeft, faArrowTurnUp, faArrowTurnDown, faPlay, faStop, faFish, faCircleMinus, faCircle, faToolbox, faPause } from '@fortawesome/free-solid-svg-icons'
import {Motion, spring} from 'react-motion';

import { Voice, ChoraleError, ChoraleErrorType, RealKey, RealNote, NCT, ChordResult } from "../engine/Engine"

import RendererNote from './RendererNote';
import Staff from "./StaffRenderer"

import useSound from 'use-sound';
import d4 from '../d4.wav';
import AudioManager from "../engine/Audio";

import Twemoji from 'react-twemoji';


let bedsPerStaff = (Config.linesPerStaff*2)-1;


var bedRefs : HTMLDivElement[] = []


function contains(r: DOMRect, x: number, y: number) {
    return x > r.x && x < r.x+r.width && y > r.y && y < r.y+r.height
}



export interface FiguredBass {
    [pos : number]: string;
 }

 const css = `
 @media screen {
 .page {
    margin-left: 20px;
 }
 .toolbar {
    padding-left: 5px !important;
 }
 .mixer {
    left: 100px;
 }
}
 .
`

class RendererBassFigure {
    val: string
    pos: number
    constructor(v: string, p: number) {
        this.val = v;
        this.pos = p;
    }

}
const brand = (print: boolean = false) => {
    return <div className={"toolbar-brand me-4"+(print? " brand-print" : "")}>
            <span className="brand-icon">
                <img src="./alt.png"></img>
            </span>
            <span className="title">chorale{print ? "" : "."}</span>
            {print && (
                <><span className="dot">.</span>
                <span className="brand-tld">app</span></>
            )}
        </div>
}

const crowdAmt = (v: number, note: RealNote, notes: Voice[]) : number => {
    let vPrev = v-1;
    let vPost = v+1;
    vPrev = notes.length - 1 - vPrev
    vPost = notes.length - 1 - vPost

    let prevNote = vPrev >= notes.length || !notes[vPrev].index(note.position) ? undefined : notes[vPrev].index(note.position);
    let nextNote = vPost < 0  || !notes[vPost].index(note.position) ? undefined : notes[vPost].index(note.position);

    let prev = !prevNote ? undefined : prevNote.letter+prevNote.octave
    let next = !nextNote ? undefined : nextNote.letter+nextNote.octave
    
    let crowd = v % 2 ? 
        (prev && incrementNote(note.letter + note.octave, 1) === prev && note.getStart() === prevNote?.getStart())  ? -1 : 0
        : 
        (next && decrementNote(note.letter + note.octave, 1) === next && note.getStart() === nextNote?.getStart()) ? 1 : 0
    return crowd
}
export interface RendererProps {
    notes: Voice[],
    measures: number
    timeSignature: string,
    k: RealKey,
    changeNotePitchAtPosition : (voice : number, time: number, pitch: string, method: boolean) => void
    changeNoteDurationAtPosition: (voice : number, time: number, duration: number) => void,
    changeFiguredBassAtPosition: (position: number, val: string) => void,
    undo: () => void,
    redo: () => void,
    mouseUp: () => void,
    removeNote: (voice: number, position: number) => void,
    setNoteAccAtPosition : (voice : number, time: number, alt: number, disableAuto?: boolean) => void,
    figuredBass : FiguredBass,
    toggleAlwaysDisplayAccidentals : (voice: number, time: number) => void
    validate: () => void,
    choraleErrors: ChoraleError[],
    analyzeChord: (pos: number, method: boolean, ignoreNCT: boolean) => string,
    chordArray: (pos: number) => ChordResult[],
    toggleNCT: (voice: number, time: number) => void
    toggleDotted: (voice: number, time: number) => void
    analyzeNCT: (voice : number, time: number) => NCT
    setChordIndex: (pos: number, val: number)=> void,
    getChordIndex: (pos: number)=> number,
    playButton: (pos: number) => void
}

//https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
export function useOnScreen(ref: RefObject<HTMLElement>) {

    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = useMemo(() => new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref, 500])
  
  
    useEffect(() => {
      observer.observe(ref.current!)
      return () => observer.disconnect()
    }, [])
  
    return isIntersecting
  }

export default function ChoraleRenderer({timeSignature, measures, notes, k, changeNotePitchAtPosition, changeNoteDurationAtPosition, undo, redo, mouseUp, removeNote, setNoteAccAtPosition, changeFiguredBassAtPosition, figuredBass, toggleAlwaysDisplayAccidentals, choraleErrors, analyzeChord, toggleNCT, toggleDotted, analyzeNCT, chordArray, getChordIndex, setChordIndex, playButton}:RendererProps) {
    notes = notes.sort((a,b) => {
       return a.id - b.id
    })
    const [hoverNote, setHoverNote] = useState("");
    const [selection, setSel] = useState(-1);
    const [activeVoice, setActiveVoice] = useState(0);
    const [scale, setScale] = useState(Config.initialScale)

    const [isMouseDown, setMouseDown] = useState(false)
    const [mousePressY, setMousePressY] = useState(0)
    const [mouseCurrentY, setMouseY] = useState(0)

    const [didLogUndo, setLogUndo] = useState(false)
    
    const [activeDropdown, setActiveDropdown] = useState(-1)

    const [enableSmart, setEnableSmart] = useState(true)
    const [enableChords, setEnabledChords] = useState(true)

    const [doBrand, setDoBrand] = useState(true)
    const [analyzeChordMode, setChordMode] = useState(true)

    const [enableFBCheck, setEnabledFBCheck] = useState(true)

    const [extraCourtesyAcc, setCourtesyAcc] = useState(false)

    const [pageSetupOpen, setPageSetup] = useState(false)

    const mixerRef = useRef<HTMLDivElement>(null)

    const [playbackRate, setPlaybackRate] = useState(1);
    const [playbackPos, setPlaybackPos] = useState(0);
    const [playbackState, setPlaybackState] = useState(false);

    const [bounce, setBounce] = useState(false);

    const playButtonRef = useRef<HTMLDivElement>(null)
    const playButtonVisible = useOnScreen(playButtonRef)

    const [meters, setMeters] = useState([0,0,0,0])

    const [gain, setGain] = useState([1,1,1,1])

    const [solos, setSolos] = useState([false, false, false, false])
    const [mutes, setMutes] = useState([false, false, false, false])

    const [mixerOpen, setMixerOpen] = useState(false)
    const [optDown, setOptDown] = useState(false)

    const [frost, setFrost] = useState(false)
    const [toolPalette, setToolPalette] = useState(true)
    const [inst, setInst] = useState([1,3,5,7])

    const [pickerOpen, setPickerOpen] = useState(false)
    const [pickerVal, setPickerVal] = useState(-1)

    const [thirdError, setThirdError] = useState(false)
    const [returnPlayhead, setReturnPlayhead] = useState(true)

    useEffect(() => {
        inst.map((x,i) => {
            AudioManager.getInstance().setSoundForVoice(i,x)
            AudioManager.getInstance().stop()
        })
    }, [inst])

    let picker = (pickerVal: number, pickerOpen: boolean, inst: number[]) => {
        
        return <div className={"mixer-picker-wrap"+(!pickerOpen ? " closed" : " open")} style={{transform: "translateX("+pickerVal*91+"px)"}}>
            {AudioManager.getInstance().instruments.map((x,i) => {
                if(i == inst[pickerVal]) return
            return <div className="mixer-icon" onClick={(e) => {
                setInst(inst.map((y,j) => {
                    if(j == pickerVal) {
                        return i
                    } else return y
                }))
                setPickerOpen(false)
                e.stopPropagation()
            }}>
            {x.iconType ? <span><FontAwesomeIcon icon={x.faIcon}/></span>: x.icon}
            </div>
            })}
            {pickerVal != -1 && <div className="mixer-icon" onClick={(e) => {
                     setPickerOpen(false)
                    e.stopPropagation()
            }}>
            {AudioManager.getInstance().instruments[inst[pickerVal]].iconType ? <span><FontAwesomeIcon icon={AudioManager.getInstance().instruments[inst[pickerVal]].faIcon!}/> </span>: AudioManager.getInstance().instruments[inst[pickerVal]].icon}
            </div>}

        </div>
    }
    let mixerVoice = (voice : number, name: string, optDown: boolean, pickerVal: number,  pickerOpen: boolean) => {
        return <div className="mixer-voice">
        <div className="mixer-head" onClick={() => {
                setPickerOpen(pickerVal == voice && pickerOpen ? false : (pickerVal != voice && pickerOpen) ? true : !pickerOpen)
                setPickerVal(voice)
            }}>
            <div className="mixer-icon">
            {AudioManager.getInstance().instruments[inst[voice]].iconType ? <span><FontAwesomeIcon icon={AudioManager.getInstance().instruments[inst[voice]].faIcon!}/> </span>: AudioManager.getInstance().instruments[inst[voice]].icon}
            </div>
            {name}
        </div>
        <div className="mixer-buttons">
            <div 
            className={"mixer-volume-buttons mixer-solo"+(solos[voice] ? " enabled" : "")}
            onClick={() => {
                if(optDown) {
                    let newSolos = solos.indexOf(!solos[voice]) != -1 ? 
                        solos.map((x) => {
                            return solos[voice]
                        }) : 
                        solos.map((x) => {
                            return !solos[voice]
                        })
                    
                    setSolos(newSolos)
                    updateGainsAndSolos(gain, newSolos, mutes)

                    return
                }
                let newSolos = solos.map((x,i) => {
                    if(i!=voice) return x;
                    return !x;
                })
                setSolos(newSolos)
                updateGainsAndSolos(gain, newSolos, mutes)
            }}
            >
                S
            </div>
            <div className={"mixer-volume-buttons mixer-mute"+(mutes[voice] ? " enabled" : "")}
                onClick={() => {
                    if(optDown) {
                        let newMutes = mutes.indexOf(!mutes[voice]) != -1 ? 
                            mutes.map((x) => {
                                return mutes[voice]
                            }) : 
                            mutes.map((x) => {
                                return !mutes[voice]
                            })
                        
                        setMutes(newMutes)
                        updateGainsAndSolos(gain, solos, newMutes)
    
                        return
                    }

                    let newMutes = mutes.map((x,i) => {
                        if(i!=voice) return x;
                        return !x;
                    })
                    setMutes(newMutes)
                    updateGainsAndSolos(gain, solos, newMutes)
                }}
            >
                M
            </div>
        </div>
        <div className="meter-container">
            <Slider key={voice} gain={gain[voice]} setGain={ newGain=> {
                    let delta = gain[voice] - newGain
                    let newGainArr = gain.map((x,i) => {
                        if(i!=voice && !optDown) return x;

                        if(optDown) {
                            return Math.max(0,Math.min(1,x - delta))
                        }

                        return newGain;
                    })
                    setGain(newGainArr)
                updateGainsAndSolos(gain, solos, mutes)
            }} />
            <div className="led-container">
                <div className="meter" style={{height: 400*meters[voice]+"px"}}>
                </div>
            </div>
        </div>
    </div>
    }
    AudioManager.getInstance().onPositionChange((newPos) => {
        if(playbackPos != newPos) {
            setPlaybackPos(newPos)
            setBounce(true)
            setActiveVoice(5)
            setSel(newPos)
        }
    })

    AudioManager.getInstance().onPlaybackStateChange((newState, returnPlayhead) => {
        if(playbackState && !newState && returnPlayhead) {
            setPlaybackState(newState)
            setPlaybackPos(0)
            setSel(0)
        } else {
            setPlaybackState(newState)
        }
    })

    AudioManager.getInstance().onMeterUpdate((newMeters) => {
        if(meters != newMeters) {
            setMeters(newMeters)
        }
    })


    if(activeVoice != 5 && selection !== -1 && !notes[activeVoice].index(selection)) {
        setSel(-1)
    }

    window.addEventListener("scroll", (e) => {
        if(window.scrollY >= 28) {
            setFrost(true)
        } else {
            setFrost(false)
        }
        if(mixerOpen) {
            setMixerOpen(false)
            setPickerOpen(false)
        }
    })
    
    //split the notes into beds, convert each note to a RendererNote
    let beds : RendererNote[][] = Array(bedRefs.length)
    let positions : number[] = []
    let gutters : RendererNote[][][] = Array(Config.staves)

    let stemDirUp = true

    let ts = TimeSignature.get(timeSignature)
    let division = ts.upper! as number/ts.lower!
    let keyLetters = k.letters
    let sig = k.sig

    const keyWidth = (keyLetters.length*Config.qNSpacing*Config.ksSpread) + 30
    var staffMarginY = 0;
    let linesAboveBelow = 0;

    const [gtr] = useSound(
         d4,
        { volume: 0.1, playbackRate: playbackRate }
      );

    let onMouseDown = (pos: number, v: number, y: number) => {
        setSel(pos)
        setActiveVoice(v)
        if(notes[v].index(pos)?.getPitch()) {
            let semi = Interval.semitones(Interval.distance("D4", notes[v].index(pos)!.getPitch()))
            setPlaybackRate(Math.pow(Math.pow(2, 1/12), semi ? semi : 0))
            gtr()
        }

        setMouseDown(true)
        setMousePressY(y)
        setMouseY(y)
        setLogUndo(false)
    }

    let onMouseMove = (pageY : number, clientX:number, clientY: number) => {
        if(activeVoice == 5) return
        if(isMouseDown) {
            setMouseY(pageY)

            let newNote = new RealNote(
                hoverNote, -1, 0, -1, 0, false, false, k)
            newNote.alt = 0

            if(hoverNote !== "" && notes[activeVoice].index(selection)?.getPitch() !== newNote.getPitch()) {
                if(!didLogUndo)
                    mouseUp()
                setLogUndo(true)
                changeNotePitchAtPosition(activeVoice, selection, hoverNote, false)
                setMousePressY(mouseCurrentY)
                
                if(notes[activeVoice].index(selection)?.getPitch()) {
                    let semi = Interval.semitones(Interval.distance("D4", notes[activeVoice].index(selection)!.getPitch()))
                    setPlaybackRate(Math.pow(Math.pow(2, 1/12), semi ? semi : 0))
                    gtr()
                }
            }
        }
        
        var ledgerLines: number;
        let staff = Config.staves - 1 - Math.floor(activeVoice/2)

        let topNote = endingNotes[staff];
        let bottomNote = Config.startingNotes[staff]; 

        let topBed = bedNumberForNote(topNote.charAt(0), parseInt(topNote.charAt(1)), staff);
        let bottomBed = bedNumberForNote(bottomNote.charAt(0), parseInt(bottomNote.charAt(1)), staff);

        let height = bedRefs[topBed].getBoundingClientRect().height
        
        let topY = bedRefs[topBed].getBoundingClientRect().y 
        let bottomY = bedRefs[bottomBed].getBoundingClientRect().y 


        if(clientY < topY) {
            let amtOfLines = Math.floor((topY - clientY) / height)
            ledgerLines = amtOfLines
        } else if(clientY > bottomY) { 
            let amtOfLines = -1 * Math.floor(( clientY - bottomY) / height)
            ledgerLines = amtOfLines
        } else {
            ledgerLines = 0
        }


        if(ledgerLines !==0 ) {
            let targetNote = ledgerLines < 0 ? Config.startingNotes[staff] : endingNotes[staff]
            if(targetNote) {
                let note = incrementNote(targetNote, ledgerLines)
    
                if(note) {
                    setHoverNote(note)
                }
            }
        }
        
        Array.from(Array(bedRefs.length), (_, bedNum) => 
        {
            if(contains(bedRefs[bedNum].getBoundingClientRect(), clientX, clientY)) {
                setHoverNote(bedRefs[bedNum].id)
            }
            return null
        })
        
    }

    let onMouseUp: MouseEventHandler = (e) => {
        setMouseDown(false)
        setMouseY(0)
        setMousePressY(0)
        setLogUndo(false)
    }
    
    let getDocumentPos = (position: number) => {
        return (
            Math.floor((Math.max(0,position-notes[0].getEngine().pickup))/division)*Config.measureSpacing) 
            + (position*Config.qNSpacing)
    }

    let v = Config.voicesPerStaff * Config.staves - 1
    var altBeam = true

    for(var voice of notes) {
        let staff = Math.floor(v/2)
        var index = 0;

        let alterations = new Array(Config.allLetters.length).fill(0);
        let lastMeasure = new Array(Config.allLetters.length).fill(0);


        for(var note of voice.sorted()) {
            let letter = note.letter
            let oct = note.octave

            if(lastMeasure[Config.allLetters.indexOf(note.letter)] != note.measure && !extraCourtesyAcc) {
                alterations[Config.allLetters.indexOf(note.letter)] = 0;
            }
            let altNew = note.alt != alterations[Config.allLetters.indexOf(note.letter)]

            let crowd = crowdAmt(v, note, notes)
            
            /*
                before and after chronologically

                here, the renderer can also check intervals for augmented 2nds, tritones, consecutive octaves
            */
            let preChron = index-1 < 0 ? undefined : voice.sorted()[index-1]
            let nextChron = index+1 >= voice.notes.length ? undefined : voice.sorted()[index+1]
            var beam = 0;
            var doBeam = false
            var endBeam = false

            let nextNoteCrowd = 0

            if(note.getDuration() === 1/8) {
                if(nextChron?.getDuration() === 1/8 && nextChron.measure === note.measure) {
                    if(altBeam) {
                        beam = calculateDistance(note.getPitch(), nextChron.getPitch())
                        nextNoteCrowd = crowdAmt(v, nextChron, notes)
                        doBeam = true
                    } else {
                        endBeam = true
                    }
                } else if(preChron?.getDuration() === 1/8 && preChron?.measure === note.measure) {
                    endBeam = true
                }
                altBeam = !altBeam
            } else {
                altBeam = true
            }
            
            let bN = bedNumberForNote(letter, oct, staff);
            if(bN !==-1) {
                if(!beds[bN]) 
                    beds[bN] = [] 
                beds[bN].push(
                    new RendererNote(note,
                                    getDocumentPos(note.position),
                                    keyWidth + Config.leftOffset,
                                    (onMouseDown), 
                                    stemDirUp,
                                    0,
                                    false,
                                    (notes.length - 1 - activeVoice === v && note.position === selection) || (activeVoice === 5 && note.getStart() <= selection && note.getEnd() > selection),
                                    false, 
                                    crowd, 
                                    beam, 
                                    doBeam, 
                                    endBeam,
                                    altNew,
                                    note.isNonChordTone ? analyzeNCT(notes.length - 1 - v, note.position) : NCT.NCT,
                                    nextNoteCrowd))
                                    
                positions.indexOf(note.position) === -1 && positions.push(note.position)
            } else {
                let ll = calculateLedgerLines(letter, oct, staff)
                if(!gutters[staff]) 
                    gutters[staff] = [] 
                if(!gutters[staff][ll > 0 ? 1 : 0])
                    gutters[staff][ll > 0 ? 1 : 0] = []
                gutters[staff][ll > 0 ? 1 : 0].push(
                    new RendererNote(note, 
                                     getDocumentPos(note.position), 
                                     keyWidth + Config.leftOffset, 
                                     onMouseDown, 
                                     stemDirUp, 
                                     ll,
                                     false,
                                     (notes.length - 1 - activeVoice === v && note.position === selection) || (activeVoice === 5 && note.getStart() <= selection && note.getEnd() > selection),
                                    false, 
                                    crowd, 
                                    beam, 
                                    doBeam, 
                                    endBeam,
                                    altNew,
                                    note.isNonChordTone ? analyzeNCT(notes.length - 1 - v, note.position) : NCT.NCT,
                                    nextNoteCrowd))
                positions.indexOf(note.position) === -1 && positions.push(note.position)

                if(staff %2 === 1 && ll > 0) {
                    linesAboveBelow = Math.max(Math.abs(ll), linesAboveBelow)
                } else if(staff %2 === 0 && ll < 0) {
                    linesAboveBelow = Math.max(Math.abs(ll), linesAboveBelow)
                }
            }

            alterations[Config.allLetters.indexOf(note.letter)] = note.alt
            lastMeasure[Config.allLetters.indexOf(note.letter)] = note.measure

            positions = positions.sort();
            index++;
        }

        stemDirUp = !stemDirUp // flip the stem for each voice
        v--;
    }

    let scaleUp = (key : boolean) => {
        //let maxPos = (positions[positions.length-1]+2/4) 
        //let maxScale = Math.floor(((750 - (keyWidth+leftOffset+5)) /  (maxPos*qNSpacing))*10)/10
        
        //setScale(Math.min(scale + 0.1, Math.floor(maxScale*10)/10))
        setScale(key ? scale + 0.3 : scale + 0.1  )
    }
    let scaleDown = (key : boolean) => {
        setScale(Math.max(key ? scale - 0.3 : scale - 0.1, 1.0))
    }

    let nudgeUp = () => {
        if(selection !==-1 && activeVoice != 5) {
            let tonal = Note.transpose((notes[activeVoice].index(selection)?.getPitch()!)!, "m2")
            tonal = Note.simplify((tonal))

            if((tonal.charAt(tonal.length-2) === "#" || tonal.charAt(tonal.length-2) === "b") && tonal.charAt(tonal.length-2) !==sig.charAt(0)) {
                tonal = Note.enharmonic(tonal)
            }
            changeNotePitchAtPosition(activeVoice, selection, tonal, true)
            mouseUp()
        }
    }

    let nudgeDown = () => {
        if(selection !==-1 && activeVoice != 5) {
            let tonal = Note.transpose((notes[activeVoice].index(selection)?.getPitch()!)!, "-2m")
            tonal = Note.simplify((tonal))

            if((tonal.charAt(tonal.length-2) === "#" || tonal.charAt(tonal.length-2) === "b") && tonal.charAt(tonal.length-2) !==sig.charAt(0)) {
                tonal = Note.enharmonic(tonal)
            }
            changeNotePitchAtPosition(activeVoice, selection, tonal, true)
            mouseUp()
        }
    }

    let keyEvent = (e : KeyboardEvent) => {
        if(e.key === "r") {
            scaleDown(true)
        } else if (e.key === "t") {
            scaleUp(true)
        }

        if(e.key === "z" && e.metaKey && e.shiftKey) {
            redo()
        }
        else if(e.key === "z" && e.metaKey ) {
            undo()
        } 
        else if(e.key == "a") {
            if(selection !==-1 && activeVoice != 5) {
                toggleAlwaysDisplayAccidentals(activeVoice, selection)
            }
        }
        else if(e.key === "Backspace") {
            if(selection !==-1 && activeVoice != 5) {
                removeNote(activeVoice, selection)
            }
        } else if (e.key === "ArrowUp" || e.key === "w") {
            if(selection !==-1 && activeVoice != 5) {
                e.preventDefault();
                nudgeUp()
            }
        } else if (e.key === "ArrowDown" || e.key === "s") {
            if(selection !==-1 && activeVoice != 5) {
                e.preventDefault();
                nudgeDown()
            }
        } else if(e.key === "x") {
            if(selection !==-1&& activeVoice != 5) {
                e.preventDefault();
                toggleNCT(activeVoice, selection)
            }   
        } else if(e.key === "d") {
            if(selection !==-1 && activeVoice != 5) {
                e.preventDefault();
                toggleDotted(activeVoice, selection)
            }   
        } else if(e.key === " ") {
            playButton(selection)
            e.preventDefault();
        } else if(e.key === "Alt") {
            setOptDown(true)
        }
    }
    
    var figures : RendererBassFigure[] = []
    for(var pos in figuredBass) {
        figures.push(new RendererBassFigure(figuredBass[pos], Math.floor((parseFloat(pos)*16))/16))
    }
    staffMarginY = Math.min(70, 22 * linesAboveBelow);

    return (
    <div className={"window user-select-none"+ (pageSetupOpen ? " disable-scroll" : "")} tabIndex={0} 
    onMouseDown={(e) => {
        if((e.target as Element).className.includes && !(e.target as Element).className.includes("dropdown") && !(e.target as Element).className.includes("actionbar-item")) {
            setActiveDropdown(-1)
        }
        if((e.target as Element).className.includes && !(e.target as Element).className.includes("note") && !(e.target as Element).className.includes("toolbar-btn") && !(e.target as Element).className.includes("dropdown") && !(e.target as Element).className.includes("actionbar-item")) {
            setSel(-1)
            if(mixerOpen && mixerRef.current && !contains(mixerRef?.current.getBoundingClientRect(), e.clientX, e.clientY)) {
                if((e.target as Element).className.includes && !(e.target as Element).className.includes("twemoji")) {
                    setMixerOpen(false)
                    setPickerOpen(false)
                }
            }
        }
    }}
    onMouseMove={(e) => {
        onMouseMove(e.pageY, e.clientX, e.clientY)
    }} onTouchMove={(e) => {
        e.preventDefault()
        let t = e.touches[0]
        onMouseMove(t.pageY, t.clientX, t.clientY)
    }} onMouseUp={onMouseUp} onMouseLeave={onMouseUp} onKeyDown={keyEvent}
    onKeyUp={(e) => {
        if(e.key === "Alt") {
            setOptDown(false)
        }
    }}
    ref={(el) => {
        if(el) {
            el.focus()
        }
    }}>
        <Twemoji options={{ className: 'twemoji' }}>

        {!toolPalette && <style>{css}</style>}
        <div className={"toolbar px-5" + (frost? " frost" : "")}>
            
            {brand()}

            <div className="actionbar">
        <div className={"actionbar-item"+(activeDropdown == 0 ? " active" : "")} onClick={((e) => {
                e.preventDefault()
                if(activeDropdown != 0) setActiveDropdown(0)
                else setActiveDropdown(-1)
            })}>
                File
                <div onClick={(e) => {
                      e.preventDefault();
                }}
                className={"dropdown-menu" + (activeDropdown == 0 ? " show" : "")} aria-labelledby="dropdownMenuButton">
                         <a className="dropdown-item" onClick={() => {
                            window.print()
                        }}>
                            <span className="dropdown-item-icon">
                               <FontAwesomeIcon icon={faPrint} /> 
                            </span>
                            Print
                            <span className="shortcut">
                                ⌘P
                            </span> 
                            </a>

                            <a className="dropdown-item" onClick={() => {
                            setPageSetup(true)
                        }}>
                            <span className="dropdown-item-icon">
                               <FontAwesomeIcon icon={faFile} /> 
                            </span>
                            Page Setup
                            </a>

                </div>
            </div>

            <div className={"actionbar-item"+(activeDropdown == 2 ? " active" : "")} onClick={((e) => {
                e.preventDefault()
                if(activeDropdown != 2) setActiveDropdown(2)
                else setActiveDropdown(-1)
            })}>
                Edit
                <div onClick={(e) => {
                      e.preventDefault();
                }}
                className={"dropdown-menu" + (activeDropdown == 2 ? " show" : "")} aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" onClick={() => {
                            undo()
                        }}>
                            <span className="dropdown-item-icon">
                               <FontAwesomeIcon icon={faArrowLeftRotate} /> 
                            </span>
                            Undo
                            <span className="shortcut">
                                ⌘Z
                            </span> 
                            </a>

                            <a className="dropdown-item" onClick={() => {
                            redo()
                        }}>
                            <span className="dropdown-item-icon">
                               <FontAwesomeIcon icon={faArrowRightRotate} /> 
                            </span>
                            Redo
                            <span className="shortcut">
                            ⇧⌘Z
                            </span> 
                            </a>
               

                            <a className={"dropdown-item" + (selection === -1 && activeVoice != 5 ? " disabled" : "")} onClick={() => {
                            nudgeUp()
                        }}>
                            <span className="dropdown-item-icon">
                               <FontAwesomeIcon icon={faArrowTurnUp} /> 
                            </span>
                            Nudge Note Up
                            <span className="shortcut">
                            W
                            </span> 
                            </a>

                            <a className={"dropdown-item" + (selection === -1 && activeVoice != 5 ? " disabled" : "")} onClick={() => {
                            nudgeDown()
                        }}>
                            <span className="dropdown-item-icon">
                               <FontAwesomeIcon icon={faArrowTurnDown} /> 
                            </span>
                            Nudge Note Down
                            <span className="shortcut">
                            S
                            </span> 
                            </a>
                            
                            <a className={"dropdown-item" + (selection === -1 && activeVoice != 5 ? " disabled" : "")} onClick={() => {
                                if(selection != -1 && 
                                    activeVoice != 5) 
                                    toggleDotted(activeVoice, selection)
                                
                            }}>
                            
                                <span className="dropdown-item-icon">
                                {selection != -1 
                                    && 
                                    activeVoice != 5 && notes[activeVoice].index(selection)?.dotted 
                                    ? 
                                <FontAwesomeIcon icon={faCheck}/ > : 
                                                   <div className="dropdown-spacer">
                                                    </div>} </span> 
                                Dot Note
                                <span className="shortcut">
                                D
                                </span> 
                                </a>

                            <a className={"dropdown-item" + (selection === -1 && activeVoice != 5 ? " disabled" : "")} onClick={() => {
                            if(selection != -1 && activeVoice != 5) 
                                toggleNCT(activeVoice, selection)
                            
                        }}>
                        
                            <span className="dropdown-item-icon">
                            {selection != -1 
                            && activeVoice != 5 
                                && 
                                notes[activeVoice].index(selection)?.isNonChordTone 
                                ? 
                            <FontAwesomeIcon icon={faCheck}/ > : 
                                               <div className="dropdown-spacer">
                                                </div>} </span> 
                            Mark Note As NCT 
                            <span className="shortcut">
                            X
                            </span> 
                            </a>



                            <a className={"dropdown-item" + (selection === -1 && activeVoice != 5 ? " disabled" : "")} onClick={() => {
                                if(selection != -1 && 
                                    activeVoice != 5) 
                                    toggleAlwaysDisplayAccidentals(activeVoice, selection)
                                
                            }}>
                            
                                <span className="dropdown-item-icon">
                                {selection != -1 
                                && activeVoice != 5 
                                    && 
                                    notes[activeVoice].index(selection)?.explicitlyDisplayAccidentals 
                                    ? 
                                <FontAwesomeIcon icon={faCheck}/ > : 
                                                   <div className="dropdown-spacer">
                                                    </div>} </span> 
                                Always Display Accidentals
                                <span className="shortcut">
                                A
                                </span> 
                                </a>

                            
                </div>
            </div>

            <div className={"actionbar-item"+(activeDropdown == 3 ? " active" : "")} onClick={((e) => {
                e.preventDefault()
                if(activeDropdown != 3) setActiveDropdown(3)
                else setActiveDropdown(-1)
            })}>
                Format
                <div onClick={(e) => {
                      e.preventDefault();
                }}
                className={"dropdown-menu" + (activeDropdown == 3 ? " show" : "")} aria-labelledby="dropdownMenuButton">

                    <a className="dropdown-item" onClick={() => {
                            scaleUp(false)
                        }}>
                            <span className="dropdown-item-icon">
                               <FontAwesomeIcon icon={faArrowsLeftRightToLine} /> 
                            </span>
                            Increase Note Spacing
                            <span className="shortcut">
                                T
                            </span> 
                            </a>

                            <a className="dropdown-item" onClick={() => {
                            scaleDown(false)
                        }}>
                            <span className="dropdown-item-icon">
                               <FontAwesomeIcon icon={faArrowLeft} /> 
                            </span>
                            Decrease Note Spacing
                            <span className="shortcut">
                            R
                            </span> 
                            </a>

                            <a className="dropdown-item" onClick={() => {
                            setCourtesyAcc(!extraCourtesyAcc)
                        }}>
                            <span className="dropdown-item-icon">
                                {!extraCourtesyAcc ? <FontAwesomeIcon icon={faCheck}/ >: 
                                               <div className="dropdown-spacer"></div>}    
                            </span>
                            Reset Accidentals Each Measure
                            </a>

                            <a className="dropdown-item" onClick={() => {
                            setDoBrand(!doBrand)
                        }}>
                            <span className="dropdown-item-icon">
                                {doBrand ? <FontAwesomeIcon icon={faCheck}/ >: 
                                               <div className="dropdown-spacer"></div>}    
                            </span>
                            Show Watermark</a>
               
                </div>
            </div>

            <div className={"actionbar-item"+(activeDropdown == 1 ? " active" : "")} onClick={((e) => {
                e.preventDefault()
                if(activeDropdown != 1) setActiveDropdown(1)
                else setActiveDropdown(-1)
            })}>
                Tools
                <div onClick={(e) => {
                      e.preventDefault();
                }}
                className={"dropdown-menu" + (activeDropdown == 1 ? " show" : "")} aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" onClick={() => {
                            setEnableSmart(!enableSmart)
                        }}>
                            <span className="dropdown-item-icon">
                                {enableSmart ? <FontAwesomeIcon icon={faCheck}/ >: 
                                               <div className="dropdown-spacer"></div>}    
                            </span>
                            Show Smart Suggestions</a>
                            <a className={"dropdown-item"} onClick={() => {
                                setEnabledFBCheck(!enableFBCheck)
                            }}>
                                <span className="dropdown-item-icon">
                                    {enableFBCheck ? <FontAwesomeIcon icon={faCheck}/ >: 
                                                    <div className="dropdown-spacer"></div>}    
                                </span>
                                Enable Figured Bass Checker</a>

                            <a className={"dropdown-item"} onClick={() => {
                                setEnabledChords(!enableChords)
                            }}>
                                <span className="dropdown-item-icon">
                                    {enableChords ? <FontAwesomeIcon icon={faCheck}/ >: 
                                                   <div className="dropdown-spacer"></div>}    
                                </span>
                                Show Chord Names</a>

                                <a className={"dropdown-item"} onClick={() => {
                                    setThirdError(!thirdError)
                                }}>
                                    <span className="dropdown-item-icon">
                                        {!thirdError ? <FontAwesomeIcon icon={faCheck}/ >: 
                                                        <div className="dropdown-spacer"></div>}    
                                    </span>
                                    Allow Leaps</a>

                        <a className={"dropdown-item" + (!enableChords ? " disabled" : "")} onClick={() => {
                            setChordMode(!analyzeChordMode)
                        }}>
                           {analyzeChordMode ? "Use Notes to Analyze Chords" : "Use Figured Bass to Analyze Chords"}</a>


                </div>
            </div>
            <div className={"actionbar-item"+(activeDropdown == 4 ? " active" : "")} onClick={((e) => {
                e.preventDefault()
                if(activeDropdown != 4) setActiveDropdown(4)
                else setActiveDropdown(-1)
            })}>
                Playback
                <div onClick={(e) => {
                      e.preventDefault();
                }}
                className={"dropdown-menu" + (activeDropdown == 4 ? " show" : "")} aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" onClick={() => {
                            AudioManager.getInstance().setReturnPlayhead(!returnPlayhead)
                            setReturnPlayhead(!returnPlayhead)
                        }}>
                            <span className="dropdown-item-icon">
                                {!returnPlayhead ? <FontAwesomeIcon icon={faCheck}/ >: 
                                               <div className="dropdown-spacer"></div>}    
                            </span>
                            Enable Pause?</a>
                </div>
            </div>
        </div>
        <div className="buttonbar">
                <div className="toolbar-btn px-2 py-1">
                <FontAwesomeIcon icon={faArrowLeftRotate} onClick={() => {
                    undo()
                }}/>
            </div>
            <div className="toolbar-btn px-2 py-1">
                <FontAwesomeIcon icon={faArrowRightRotate}  onClick={() => {
                    redo()
                }}/>
            </div>
            <div className="toolbar-btn px-2 py-1 me-3" onClick={() => {
                window.print()
            }}>
                <FontAwesomeIcon icon={faPrint} />
            </div>

            <div className="toolbar-btn px-2 py-1" onClick={() => {
                scaleDown(false)
            }}>
                <FontAwesomeIcon icon={faMinus} />
            </div>

            <div className="toolbar-btn px-2 py-1 sc">
                {Math.ceil(scale*10)*10}
            </div>


            <div className="toolbar-btn px-2 py-1 me-4" onClick={() => {
                scaleUp(false)
            }}>
                <FontAwesomeIcon icon={faPlus} />
            </div>
        </div>
        </div>
        <div className="editor">

            {pageSetupOpen && <div className="setup-wrapper" onClick={(e) => {
                    if (e.target !== e.currentTarget)
                    return;
                    setPageSetup(false)
            }}>
                <div className="setup">
                    <h3>Page Setup</h3>
                    <div className="input-group">
                        <div className="label">Key</div>
                        <div className="val">
                            <input className="key" value={k.key}></input>
                        </div>
                    </div>
                    <div className="input-group">
                    <div className="label">Time Signature</div>
                        <div className="val">
                            <input className="top" value={ts.upper! + ""}></input>
                            <input className="bot" value={ts.lower!}></input>
                        </div>
                    </div>
                    <div className="input-group">
                        <div className="label">Pickup Length</div>
                            <div className="val">
                                <input className="top" value={notes[0].getEngine().pickup}></input>
                            </div>
                        </div>
                </div>
            </div>}

            <div className={"panel"+(toolPalette ? " open" : " closed")}>
            <div className="tool-palette-closed">

            <div className={"toolbar-btn tool-palette note-palette eighth-palette "} onClick={() => {
                setToolPalette(true)
            }}>
                <FontAwesomeIcon icon={faToolbox}/>
                </div>
            </div>
            <div className="tool-palette-top">

            <div className={"toolbar-btn tool-palette note-palette eighth-palette " +(
                            (selection !==-1 && 
                                activeVoice != 5) && notes[activeVoice].index(selection)?.getDuration() === 0.125 ? "sel" : ""
                )} onClick={() => {
                if(selection !==-1 && activeVoice != 5) {
                    changeNoteDurationAtPosition(activeVoice, selection, 0.125)
                }
            }}>
                𝅘𝅥𝅮
                </div>
            <div className={"toolbar-btn tool-palette note-palette " +(
                            (selection !==-1 && 
                                activeVoice != 5) && notes[activeVoice].index(selection)?.getDuration() === 0.25 ? "sel" : ""
                )} onClick={() => {
                if(selection !==-1 && activeVoice != 5) {
                    changeNoteDurationAtPosition(activeVoice, selection, 0.25)
                }
            }}>


                    𝅘𝅥
                </div>
                <div className={"toolbar-btn tool-palette note-palette "+
                            ((selection !==-1 && 
                                activeVoice != 5) && notes[activeVoice].index(selection)?.getDuration() === 0.5 ? "sel" : ""
                        )} onClick={() => {
                    if(selection !==-1 && activeVoice != 5) {
                        changeNoteDurationAtPosition(activeVoice, selection, 0.5)
                    }
                }}>
                    𝅗𝅥
                    </div>
            <div className={"toolbar-btn tool-palette note-palette "+((selection !==-1 && 
                                    activeVoice != 5) && notes[activeVoice].index(selection)?.getDuration() === 1 ? "sel" : ""
        )} onClick={() => {
                    if(selection !==-1 && 
                        activeVoice != 5) {
                        changeNoteDurationAtPosition(activeVoice, selection, 1)
                    }
                }}>
                        𝅝
            </div>
            <div className={"toolbar-btn tool-palette note-palette eighth-palette "+((selection !==-1 && 
                                    activeVoice != 5) && notes[activeVoice].index(selection)?.dotted ? "sel" : ""
        )} onClick={() => {
                    if(selection !==-1 && 
                        activeVoice != 5) {
                        toggleDotted(activeVoice, selection)
                    }
                }}>
                         𝅭
            </div>
        </div>
        <div className="tool-palette-mid">
        <div className={"toolbar-btn tool-palette acc-palette "+
        ((selection !==-1 && 
            activeVoice != 5) 
        && notes[activeVoice].index(selection)?.getAccidentals() === "♮" 
            ? "sel" : ""
            )} onClick={() => {
                        if(selection !==-1 && 
                            activeVoice != 5) {
                            if(notes[activeVoice].index(selection)?.getAccidentals() != "" && notes[activeVoice].index(selection)?.getAccidentals() != "♮") {
                                setNoteAccAtPosition(activeVoice, selection, 0, false)
                            } else {
                                toggleAlwaysDisplayAccidentals(activeVoice, selection)
                            }
                        }
                    }}>
                        ♮
                </div>
            <div className={"toolbar-btn tool-palette acc-palette "+((selection !==-1 && 
                                    activeVoice != 5) && notes[activeVoice].index(selection)?.getAccidentals().includes("#") ? "sel" : ""
            )} onClick={() => {
                        if(selection !==-1 && 
                            activeVoice != 5) {
                            if((notes[activeVoice].index(selection)?.getAccidentals() === "#" 
                            && notes[activeVoice].index(selection)?.explicitlyDisplayAccidentals) || 
                            notes[activeVoice].index(selection)?.getAccidentals() !== "#"
                            ) {
                                setNoteAccAtPosition(activeVoice, selection, 1)
                            } else {
                                toggleAlwaysDisplayAccidentals(activeVoice, selection)
                            }
                        }
                    }}>
                            {convertAccidentals("#")}
                </div>
                <div className={"toolbar-btn tool-palette acc-palette "+((selection !==-1 && 
                                    activeVoice != 5) && notes[activeVoice].index(selection)?.getAccidentals().includes("b") ? "sel" : ""
            )} onClick={() => {
                        if(selection !==-1 && activeVoice != 5) {
                            if((notes[activeVoice].index(selection)?.getAccidentals() === "b" 
                            && notes[activeVoice].index(selection)?.explicitlyDisplayAccidentals) || 
                            notes[activeVoice].index(selection)?.getAccidentals() !== "b"
                            ) {
                                setNoteAccAtPosition(activeVoice, selection, -1)
                            } else {
                                toggleAlwaysDisplayAccidentals(activeVoice, selection)
                            }
                        }
                    }}>
                            {convertAccidentals("b")}
                </div>

                 </div>
                <div className="tool-palette-bottom">
                    {notes.map((v, x) => {
                    return <div key={x} className={"toolbar-btn tool-palette"+(notes.length - 1 - activeVoice === x ? " sel" : "") + (x===Config.voiceLabels.length - 1 ? " mb-3" : "")} onClick={() => {
                            setActiveVoice(notes.length - 1 - x)
                        }}>{Config.voiceLabels[Config.voiceLabels.length - 1 - x]}</div> 
                    })}

                    <div className="tool-palette hovered-note pe-none">
                        
                        {
                            (selection !==-1 && 
                                activeVoice != 5) ?
                                convertAccidentals(notes[activeVoice].index(selection)?.getPitch()!)
                            :
                            convertAccidentals((hoverNote.indexOf("-") === -1) ? hoverNote : "")
                            
                        }
                    </div>

                    <div className="panel-close" onClick={() => {
                        setToolPalette(!toolPalette)
                    }}>
                        <FontAwesomeIcon icon={faCircleMinus}/>
                    </div>
                </div>
            </div>
            <div className="document">        
            <Motion style={{scale: spring(scale, { stiffness: 200, damping: 8 }), staffMarginY: spring(staffMarginY, { stiffness: 500, damping: 20 })}}>
                        {({scale, staffMarginY}) =>
                        {
                            let staffWidth = ((Config.qNSpacing + Config.measureSpacing)*division*(measures - (notes[0].getEngine().pickup == 0 ? 0 : 1)))*scale + Config.leftOffset+keyWidth + ((notes[0].getEngine().pickup != 0 ? Config.measureSpacing + 10: 0) + notes[0].getEngine().pickup * Config.qNSpacing)
                            let staffHeight =  ((bedsPerStaff * Config.staves * Config.bedHeight  + ((Config.staves-1)* staffMarginY)) - 10)

                            return <>
                            <div className="page" style={{minWidth: 'calc('+ staffWidth + "px + 1.5in + 1px)" }}>
                                    <style>
                                     {`@media print {
                                        .page-content {
                                            zoom: `+Math.round((1500 / Math.round(staffWidth))*100)/100 +`;
                                        }
                                    }`}
                                    </style>
                                    <div className="page-content" onClick={(e) => {
                                            if (e.target !== e.currentTarget)
                                            return;

                                            setSel(-1)
                                            if(mixerOpen && mixerRef.current && !contains(mixerRef?.current.getBoundingClientRect(), e.clientX, e.clientY)) {
                                                setMixerOpen(false)
                                                setPickerOpen(false)
                                            }
                                    }}>
                                                <div className={"play-fish"+(bounce ? " bounce" : "")+(playbackState ? " visible" : "")} key="fish" 
                                                onAnimationEnd={()=> setBounce(false)}
                                                style={{left: 
                                                                            getDocumentPos(playbackPos) * scale + keyWidth + Config.leftOffset + 62
                                                                }}>
                                                    <FontAwesomeIcon icon={faFish} />
                                                </div>                                     
                                        {enableChords && 
                                            <div className="chord-names my-3" onClick={(e) => {
                                                setSel(-1)
                                                if(mixerOpen && mixerRef.current && !contains(mixerRef?.current.getBoundingClientRect(), e.clientX, e.clientY)) {
                                                    setMixerOpen(false)
                                                    setPickerOpen(false)
                                                }
                                            }}>
                                                {notes[0].sorted().map(x=>x.position).concat(Object.keys(figuredBass).map(x=>parseFloat(x))).filter(function(elem, index, self) { return index === self.indexOf(elem); }).sort().map((x,) => {
                                                      if(!analyzeChordMode) {
                                                        let chord = analyzeChord(x, analyzeChordMode, false).replace("##","x")

                                                        return <div key={x} className="chord-name" style={{left: getDocumentPos(x) * scale + keyWidth + Config.leftOffset}}> {chord}</div>
                                                      }
                                                   
                                                   let crds = chordArray(x)
                                                    let chord = getChordIndex(x) == crds.length ? "" : crds[getChordIndex(x)].chord.replace("##","x").replace("aug","+")
                                                

                                                    return <div 
                                                    key={x} 
                                                    className="chord-name" 
                                                    onClick={() => {
                                                        let newPos = getChordIndex(x)+1
                                                        if(newPos > crds.length) {
                                                            newPos = 0
                                                        }
                                                        setChordIndex(x, newPos)
                                                    }}
                                                    style={{left: 
                                                                    getDocumentPos(x) * scale + keyWidth + Config.leftOffset
                                                        }}> {chord}</div>
                                                })}
                                        </div>
                                        }
                                        <div className="staves">
                                                <div className="brace-left" style={{height:  "calc(6rem + " + staffHeight + "px)"}}>
                                                    <div className="brace-top"></div>
                                                    <div className="brace-bottom"></div>
                                                </div>
                                                <div className="content">
                                                    {Array.from(Array(Config.staves), (_, staff) =>
                                                      <Staff 
                                                        staff={staff} 
                                                        staffWidth={staffWidth}
                                                        isFirst={staff === 0} 
                                                        staffMarginY={staffMarginY}
                                                        clef={Config.clefs[staff]}
                                                        firstNote={Config.startingNotes[staff]}
                                                        lastNote={endingNotes[staff]}
                                                        timeSignature={timeSignature}
                                                        k={k}
                                                        scale={scale}
                                                        mousePressY={mousePressY}
                                                        mouseCurrentY={mouseCurrentY}
                                                        setRef={(x, el) => {
                                                            bedRefs[x] = el
                                                        }}
                                                        notes={beds.slice((staff)*bedsPerStaff,(staff+1)*bedsPerStaff)}
                                                        gutters={gutters[staff]}
                                                        key={staff}
                                                        ></Staff>
                                                    )}
                                                </div>
                                                <div className="figured-bass my-3" onClick={(e) => {
                                                    setSel(-1)
                                                    if(mixerOpen && mixerRef.current && !contains(mixerRef?.current.getBoundingClientRect(), e.clientX, e.clientY)) {
                                                        setMixerOpen(false)
                                                        setPickerOpen(false)
                                                    }
                                                }}>
                                                    {notes[0].sorted().map(x=>x.position).concat(Object.keys(figuredBass).map(x=>parseFloat(x))).filter(function(elem, index, self) { return index === self.indexOf(elem); }).sort().map((x) => {
                                                        return <BassFigure key={x} enableFBCheck={enableFBCheck} satisfied={
                                                            analyzeChord(x, true, false) === 
                                                            analyzeChord(x, false, true) 
                                                            && analyzeChord(x,true,false) !== ""} posX={getDocumentPos(x) * scale + keyWidth + Config.leftOffset} text={figuredBass[x] ? figuredBass[x] : ""} setText={(e)=> {
                                                            changeFiguredBassAtPosition(x, e)
                                                        }}></BassFigure>
                                                    })}
                                                </div>
                                                <div className="dividers py-5 my-4">
                                                    <div className="divider" style={{height: "calc(6rem + " + staffHeight + "px)"}}></div>
                                                        {positions.map(x => {
                                                        if(x!==0 && (x-notes[0].getEngine().pickup)%division === 0) {
                                                            return <div key={x} className="divider" style={{left: getDocumentPos(x)*scale + keyWidth+Config.leftOffset+40, height: "calc(6rem + " + staffHeight + "px)"}}></div>
                                                        } else return null
                                                        })} 

                                                    <div className="divider" style={{left: staffWidth, height: "calc(6rem + " + staffHeight + "px)"}}></div>
                                                    <div className="divider" style={{left: staffWidth-5, height: "calc(6rem + " + staffHeight + "px)"}}></div>
                                                </div>
                                                {enableSmart && (
                                                <div className="smart-check">
                                                    {choraleErrors.map((err) => {
                                                        if(err.errorType === ChoraleErrorType.augmented) {
                                                            return drawLine(err.posStart, 
                                                                            err.posEnd, 
                                                                            "A" + Math.abs(err.interval),
                                                                            "augmented",
                                                                            err.voices[0],
                                                                            notes[err.voices[0]], 
                                                                            getDocumentPos, 
                                                                            scale,
                                                                            keyWidth + Config.leftOffset,
                                                                            staffHeight, 
                                                                            staffMarginY,
                                                                            Config.measureSpacing)
                                                        } else if(err.errorType === ChoraleErrorType.leap && thirdError) {
                                                            return drawLine(err.posStart, 
                                                                err.posEnd, 
                                                                ">"+Config.maxLeap,
                                                                "leap",
                                                                err.voices[0],
                                                                notes[err.voices[0]], 
                                                                getDocumentPos, 
                                                                scale,
                                                                keyWidth + Config.leftOffset,
                                                                staffHeight, 
                                                                staffMarginY,
                                                                Config.measureSpacing)
                                                        } else if(err.errorType === ChoraleErrorType.parallel) {
                                                                    return <>
                                                                    {drawVerticalLine(
                                                                        err.posStart, 
                                                                        "="+err.interval,
                                                                        err.voices.map((vI) => {
                                                                            return notes[vI]
                                                                        }), 
                                                                        getDocumentPos, 
                                                                        scale,
                                                                        keyWidth + Config.leftOffset,
                                                                        staffHeight, 
                                                                        staffMarginY)}
                                                                        
                                                                    {drawVerticalLine(
                                                                        err.posEnd, 
                                                                        "="+err.interval,
                                                                        err.voices.map((vI) => {
                                                                            return notes[vI]
                                                                        }), 
                                                                        getDocumentPos, 
                                                                        scale,
                                                                        keyWidth + Config.leftOffset,
                                                                        staffHeight, 
                                                                        staffMarginY)}

                                                                        </>
                                                        } else if(err.errorType === ChoraleErrorType.seventh) {
                                                           
                                                            return drawLine(err.posStart, 
                                                                err.posEnd, 
                                                                "7⌄",
                                                                "sev",
                                                                err.voices[0],
                                                                notes[err.voices[0]], 
                                                                getDocumentPos, 
                                                                scale,
                                                                keyWidth + Config.leftOffset,
                                                                staffHeight, 
                                                                staffMarginY,
                                                                Config.measureSpacing)
                                                        } 
                                                    })}
                                                </div>
                                                )}
                                                {doBrand && brand(true)}
                                                    
                                        </div>

                                        
                                    </div>

                                    <div ref={playButtonRef} className={"play-button-wrapper"+ (!playButtonVisible ? " f-invisible" : " f-visible")}>
                <div className="play-button" onClick={() => {
                    playButton(selection);
                }}> 
                    {playbackState ? (returnPlayhead ? <FontAwesomeIcon icon={faStop} /> : <FontAwesomeIcon icon={faPause} /> ) :  <FontAwesomeIcon icon={faPlay} />}
                </div>
            </div>

                                </div>
                            </>
                        }} 
                    </Motion>
                    
                    <div onClick={(e) => {
                        if(!mixerOpen || e.currentTarget == e.target) {
                            setMixerOpen(!mixerOpen)
                            setPickerOpen(false)

                        }
                    }} ref={mixerRef} className={"mixer "+(mixerOpen ? "open" : "closed")}>
                        <div className="mixer-head" onClick={() => {
                            setMixerOpen(!mixerOpen)
                            setPickerOpen(false)
                        }}>
                             <FontAwesomeIcon icon={faPlus} />
                        </div>
                        <div className="mixer-bd-closed">
                            
                        </div>
                        <div className="mixer-closed">
                            OPEN MIXER
                        </div>
                        <div className="mixer-style-stripe">

                        </div>

                        {mixerVoice(0,"Bass", optDown, pickerVal, pickerOpen)}
                        {mixerVoice(1,"Tenor", optDown, pickerVal, pickerOpen)}
                        {mixerVoice(2,"Alto", optDown, pickerVal, pickerOpen)}
                        {mixerVoice(3,"Soprano", optDown,pickerVal, pickerOpen)}
                    </div>
                    <div className="mix-picker">
                        {picker(pickerVal, pickerOpen, inst)}
                    </div>
                    <div className={"play-button-wrapper fixed" + (playButtonVisible ? " f-invisible" : " f-visible")}>
                <div className="play-button" onClick={() => {
                    playButton(selection);
                }}> 
                    {playbackState ?  (returnPlayhead ? <FontAwesomeIcon icon={faStop} />  : <FontAwesomeIcon icon={faPause} />):  <FontAwesomeIcon icon={faPlay} />}
                </div>
            </div>

        </div>
      </div>
     </Twemoji>
</div>)
}


function updateGainsAndSolos(gains: number[], solos: boolean[], mutes: boolean[]) {
    let noSolos = solos.indexOf(true) == -1
    gains.map((x,i) => {
        AudioManager.getInstance().setGain(i, mutes[i] ? 0 : noSolos ? x : (solos[i] ? x : 0))
    })
}
interface Slider {
    gain: number,
    setGain: (gain: number) => void
}


function Slider({gain, setGain}:Slider)  {
    const ref = useRef<HTMLDivElement>(null)
    const buttonRef = useRef<HTMLDivElement>(null)

    const [isTracking, setTracking] = useState(false)
    const [mouseClickY, setClickY] = useState(-1)
    const [mouseY, setY] = useState(-1)

    const tramHeight = 145
   let mouseMove = (e: { clientY: number; }) => {
        if(isTracking && ref.current)  {
            let newGain = gain - ((e.clientY - mouseClickY) / tramHeight)
            if(newGain <= 1 && newGain >= 0) {
                setY(e.clientY)
                setClickY(e.clientY)
                setGain(Math.min(1,Math.max(0,newGain)))
            }
        }
    }

    let mouseUp = () => {
        if(!isTracking) return
        setTracking(false)
    }
    useEffect(() => {
        window.document.addEventListener("mousemove", mouseMove)
        window.document.addEventListener("mouseup", mouseUp)

        return () => {
            window.document.removeEventListener("mousemove", mouseMove)
            window.document.removeEventListener("mouseup", mouseUp)
        };

    }, [isTracking, gain, mouseClickY])


    const transformDelta = () => {
        let newGain = gain - ((mouseY - mouseClickY) / tramHeight)
        if(newGain < 0) {
            return ((gain) * tramHeight) 
        } else if(newGain > 1) {
           return (tramHeight - (gain*tramHeight)) * -1
        } else return (mouseY - mouseClickY)
    }
    return <div className="slider" ref={ref}>
    <div className="slider-button" key={"btn"} ref={buttonRef}
    onMouseDown={(e) => {
        if(contains(e.currentTarget.getBoundingClientRect(), e.clientX, e.clientY)) {
            setClickY(e.clientY)
            setY(e.clientY)
            setTracking(true)
            e.preventDefault()
            e.stopPropagation()
        }
    }
        
    }
    style={{
        bottom: gain*tramHeight+ "px",
        transform: isTracking ? "translateY(" + transformDelta() + "px)" : ""
    }}
    >
    </div>
    <div className="strip"></div>
</div>

}
function drawVerticalLine(pos: number, text: string, voices: Voice[], getDocumentPos : (position: number) => number, scale: number, leftMargin: number, staffHeight: number, staffMargin: number) {    
    staffMargin += 39 * 2 //staff padding 

    let startVoice = voices[0]
    let endVoice = voices[1]

    let startNote = startVoice.index(pos)
    let endNote = endVoice.index(pos)

    if(!startNote || !endNote)
        return null
    let diff = calculateDistance(startNote!.getPitch(), endNote!.getPitch())

    let docuPos = getDocumentPos(startNote!.position)*scale + leftMargin - 8
   
    let highestNote =  (diff > 0 ? startNote : endNote)!
    let lowestNote =  (diff < 0 ? startNote : endNote)!


    let highStaff = Math.floor(highestNote.voice/2)
    highStaff = Config.staves - 1 - highStaff

    let lowStaff = Math.floor(lowestNote.voice/2)
    lowStaff = Config.staves - 1 - lowStaff


    let startY = calculateDistance(endingNotes[0], highestNote.getPitch())* Config.bedHeight + (highStaff * staffMargin)
    let endY = calculateDistance(endingNotes[0], lowestNote.getPitch())* Config.bedHeight + (lowStaff * staffMargin)

    let lineHeight = Math.abs(startY - endY)
    
    return  <div className={"checker-line-wrapper" + 
            (" checker-line-vert")}
        style={{
           left: docuPos,
           top: startY,
           height: Math.abs(lineHeight)
        }}
        >
                <div style={{height: Math.abs(lineHeight)}}>
                    <div className="checker-line"></div> 
                    <div className="checker-int"
                     style={{top: Math.abs(lineHeight/2) - 10}} >
                        {text}
                    </div>
                </div>

        </div>
}

function drawLine(posStart: number, posEnd: number, text: string, type: string, voiceNumber: number, notes: Voice, getDocumentPos : (position: number) => number, scale: number, leftMargin: number, staffHeight: number, staffMargin: number, measureSpacing: number) {
    let startPos = posStart
    let endPos = posEnd
    let voice = voiceNumber
    let staff = Math.floor(voice/2)
    staff = Config.staves - 1 - staff

    let startNote = notes.index(startPos)
    let endNote = notes.index(endPos)

    if(!startNote || !endNote)
        return null
    
    let diff = calculateDistance(startNote!.getPitch(), endNote!.getPitch())

    let docuPos = getDocumentPos(startNote!.position)*scale + leftMargin - 8
   
    let lineAngle = 0
    let lineSide = Config.qNSpacing * (endPos-startPos) * scale

    if(startNote?.measure != endNote?.measure) {
        lineSide += (measureSpacing)*scale
    }
    
    let highestNote =  (diff > 0 ? startNote : endNote)
    let highestNoteLedgerLines = calculateLedgerLines(highestNote!.letter, highestNote!.octave, staff)

    var lineWidth = lineSide
    let lineHeight = diff*Config.bedHeight
    
    if(diff !== 0) {
        lineAngle = (Math.atan(lineHeight/lineSide)*180)/Math.PI
        lineWidth = Math.sqrt(lineSide*lineSide + lineHeight*lineHeight);
    } else {
        lineHeight = Config.bedHeight
    }
    
    let bedNum = bedNumberForNote(highestNote!.letter, highestNote!.octave, staff)
    if(bedNum < 0 && staff === 1) {
        bedNum= bedNum + bedsPerStaff*(staff)
    } 

    let top = bedNum*Config.bedHeight + (staff * (staffMargin+100))
    
    top -= Math.max(0,(Math.abs(highestNoteLedgerLines)-1)) * Config.bedHeight

    if(highestNoteLedgerLines < 0) {
        top = ((Config.bedHeight) * (Math.abs(highestNoteLedgerLines)-1)) + (staff+1) * (bedsPerStaff * Config.bedHeight) + ((staff* (staffMargin+100)))
    }

    return  <div className={"checker-line-wrapper" + 
        (lineAngle < 0 ? " line-to-top" : " line-to-bottom")+
            (" checker-line-"+voice)+
            (" checker-type-"+type)+
        (Math.abs(diff) === 0 ? " checker-line-flat" : "")}
        style={{width: lineSide, 
           left: docuPos,
           top: top,
           height: Math.abs(lineHeight)
        }}
        key={posStart + "-" +voiceNumber+text}
        >
                <div style={{height: Math.abs(lineHeight)}}>
                    <div className="checker-line" 
                            style={{width: Math.ceil(lineWidth+6), 
                                marginTop: lineAngle < 0 ? Math.abs(lineHeight) :0,
                                transform: "rotate(" + lineAngle +"deg)",
                                transformOrigin: lineAngle < 0 ? "0 100%" :0
                                }}></div> 
                    <div className="checker-int"
                     style={{left: Math.abs(lineSide/2), top: Math.abs(lineHeight/2) - 15}}
                        >
                        {text}
                    </div>
                </div>

        </div>
}

interface BassFigure {
    posX: number
    text: string,
    setText: (newVal: string) => void,
    satisfied: boolean, 
    enableFBCheck: boolean
}

// eslint-disable-next-line
function BassFigure({posX,text, setText, satisfied, enableFBCheck}:BassFigure) {
    const [hover, setHover] = useState(false)
    const [active, setActive] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    return <>  {satisfied && enableFBCheck && <div className="fb-check" style={{left: posX}}><div className="fb-check-content"><FontAwesomeIcon icon={faCheck}/ ></div></div>}    
  <div onMouseEnter={() => {
        setHover(true)
    }} 
    onMouseLeave={() => {
        setHover(false)
    }} 
    onMouseDown={() => {
        if(hover) {
            if(ref) {
                ref.current?.focus()
                setActive(true)
            }
        }
        else 
            setActive(false)
    }}
    onKeyDown={(e) => {
        if(active) {
            let v = e.key

            if(v === "Backspace") {
                if(text.length > 0) {
                    v = text.substring(0, text.length-1)
                    setText(v)
                } 
            }else {
                var newText = ""
                if(v==="Enter") {
                    v=","
                }
                v = v.replaceAll(/[^0-9#bn,-/\\]/g, '');
                if(text) 
                    newText = text+v
                else 
                    newText = v
                
                setText(newText)
            }
        }
    }}
    ref={ref}
    tabIndex={0}
    className={"bass-figure mx-3"  + (text.length === 0 ? " empty" : "") + (hover ? " hover" : "")} style={{left: posX}}>
            <>{(text.length === 0 && hover && !active) && (
                <FontAwesomeIcon icon={faPlus} className="pe-none bass-figure-plus"/>
            )}
            {text}</>
        </div>  </>
}

