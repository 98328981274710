import { Component } from 'react'; // let's also import Component
import Renderer, { FiguredBass } from "./render/Renderer"

import ImmutableChorale, { ChoraleError, RawNote, RealKey } from "./engine/Engine"

var _ = require('lodash');

let undoState: ImmutableChorale[] = []
let redoState: ImmutableChorale[] = []

type ChoraleState = {
  chorale: ImmutableChorale,
  errors: ChoraleError[]
}
let voiceRanges = [
  {
    low: "E2",
    hi: "E4"
  }, {
    low: "C3",
    hi: "A4"
  },
  {
    low: "F3",
    hi: "D5"
  }, {
    low: "C4",
    hi: "C6"
  }
]

const notes : RawNote[][]=
[
  [
    new RawNote("A3",1/4), 
    new RawNote("D3",1/2), new RawNote("E3",1/4), 
    new RawNote("C#3",1/2), new RawNote("A2",1/4), 
    new RawNote("D3",1/2), new RawNote("E3",1/4), 
    new RawNote("F#3",1/2), new RawNote("E3",1/4), 
    new RawNote("D3",1/2,"",true),
    new RawNote("C#3",1/2,"",true),

    new RawNote("B2",1/2,"",true),
    new RawNote("E3",1/2), new RawNote("E3",1/4), 
    new RawNote("F3",1/2),  new RawNote("D3",1/4),
    new RawNote("E3",1/2), new RawNote("C#3",1/4),
    new RawNote("F#3",1/2), new RawNote("B2",1/4), 
    new RawNote("D3",1/2), new RawNote("E3",1/4), 
    new RawNote("A2",1/2), 
  ],[
    new RawNote("C#4",1/4), 
    new RawNote("D4",1/2),  new RawNote("B3",1/4), 
    new RawNote("A3",1/2),  new RawNote("A3",1/4), 
    new RawNote("A3",1/2),  new RawNote("G#3",1/4), 
    new RawNote("F#3",1/2), new RawNote("A3",1/4),
    new RawNote("A3",1/2),  new RawNote("G#3",1/4), 
    new RawNote("G#3",1/2), new RawNote("F#3",1/4), 

    new RawNote("F#3",1/2), new RawNote("F3",1/4), 
    new RawNote("E3",1/2), new RawNote("G#3",1/4), 
    new RawNote("A3",1/2),  new RawNote("A3",1/4), 
    new RawNote("G#3",1/2),  new RawNote("A3",1/4), 
    new RawNote("A#3",1/4), new RawNote("E4",1/4), new RawNote("D4",1/4),
    new RawNote("B3",1/2), new RawNote("B3",1/4),
    new RawNote("A3",1/2), 
  ],
  [
    new RawNote("E4",1/4), 
    new RawNote("F4",1/2), new RawNote("E4",1/4), 
    new RawNote("E4",1/2), new RawNote("E4",1/4), 
    new RawNote("F4",1/4), new RawNote("E4",1/4), new RawNote("D4",1/4),
    new RawNote("C#4",1/2), new RawNote("C#4",1/4),
    new RawNote("C#4",1/2), new RawNote("B3",1/4),
    new RawNote("B3",1/2), new RawNote("A3",1/4),

    new RawNote("B3",1/2), new RawNote("A3",1/4),
    new RawNote("G#3",1/2), new RawNote("D4",1/4),
    new RawNote("C4",1/2), new RawNote("F4",1/4),
    new RawNote("E4",1/2), new RawNote("E4",1/4),
    new RawNote("F#4",1/2,"",true), 
    new RawNote("F#4",1/2), new RawNote("E4",1/4), 
    new RawNote("C#4",1/2)
    ],
  [
    new RawNote("A4",1/4), 
    new RawNote("B4",1/2), new RawNote("G#4",1/4),
    new RawNote("A4",1/2),  new RawNote("C#5",1/4),
    new RawNote("B4",1/2,"", true),
    new RawNote("A4",1/2), new RawNote("G#4",1/4),
    new RawNote("F#4",1/2,"", true),
    new RawNote("E4",1/2,"", true),

    new RawNote("D4",1/2,"",true),
    new RawNote("B3",1/4),new RawNote("E4",1/4),new RawNote("B4",1/4),
    new RawNote("A4",1/2),new RawNote("D5",1/4),
    new RawNote("D5",1/4),new RawNote("B4",1/4),new RawNote("E5",1/4),
    new RawNote("E5",1/4),new RawNote("A#4",1/4),new RawNote("D5",1/4),
    new RawNote("C#5",1/4),new RawNote("B4",1/4), new RawNote("A4",1/8),new RawNote("G#4",1/8),
    new RawNote("A4",1/2),

    ]
  ] as RawNote[][]


  const figuredBass : FiguredBass = {
    0.25: "6,n",
    1: "6",
    1.75: "65,n",
    2: "-,-,2",
    2.25: "7",
    3: "43",
    3.25: "753",
    3.75: "64,-",
    4: "753",
    4.5: "64,-",
    4.75: "85",
    5.25: "7,n5",
    6: "7",
    6.25: "n5",
    6.75: "n",
    7: "7",
    7.25: "5",
    7.5: "6",
    7.75: "7,#",
    8: "#,7",
    8.25: "33",
    8.5: "76",
    8.75: "6,-",
    9: "4",
    9.125: "3"
  }



export class Chorale extends Component<{}, ChoraleState> {


  componentWillMount() {
    this.setState({
      chorale: new ImmutableChorale(
        notes,
        voiceRanges,
        new RealKey("A"),
        "3/4",
        figuredBass,
        0.25,
        () => {
          this.logUndo()
        }),
      errors: []
    }, () => {
      this.validate();
    })
  }


  changeNotePitchAtPosition(voice: number, time: number, pitch: string, method: boolean) {
    this.setState({ chorale: this.state.chorale.changeNotePitchAtPosition(voice, time, pitch, method) }, () => {
      this.validate()
    })
    this.state.chorale.stopAudio()
  }

  changeNoteDurationAtPosition(voice: number, time: number, duration: number) {
    this.setState({ chorale: this.state.chorale.changeNoteDurationAtPosition(voice, time, duration) }, () => {
      this.validate()
    })
    this.state.chorale.stopAudio()
  }
  setNoteAccAtPosition(voice: number, time: number, alt: number, disableAuto: boolean = true) {
    this.setState({ chorale: this.state.chorale.setNoteAccAtPosition(voice, time, alt) }, () => {
      this.validate()
    })
    this.state.chorale.stopAudio()
  }
  changeFiguredBassAtPosition(time: number, val: string) {
    this.setState({ chorale: this.state.chorale.changeFiguredBassAtPos(time, val) }, () => {
      this.validate()
    })
    this.state.chorale.stopAudio()
  }

  toggleAlwaysDisplayAccidentals(voice: number, time: number) {
    this.setState({ chorale: this.state.chorale.toggleExplicitlyDisplayAccidentals(voice, time) }, () => {
      this.validate()
    })
  }
  removeNote(voice: number, time: number) {
    this.setState({ chorale: this.state.chorale.removeNote(voice, time) }, () => {
      this.validate()
    })
    this.state.chorale.stopAudio()
  }

  toggleNCT(voice: number, time: number) {
    this.setState({ chorale: this.state.chorale.toggleNCT(voice, time) }, () => {
      this.validate()
    })
  }
  toggleDotted(voice: number, time: number) {
    this.setState({ chorale: this.state.chorale.toggleDotted(voice, time) }, () => {
      this.validate()
    })
    this.state.chorale.stopAudio()
  }
  logUndo = () => {
    undoState.push(_.cloneDeep(this.state.chorale))
    undoState = _.reject(undoState, (val: any, i: number) => {
      return i > 0 && _.isEqual(undoState[i - 1], val);
    }).slice(-15);
  }

  mouseUp() {
    this.logUndo();
    redoState = []
  }

  undo() {
    let undid = undoState.pop();
    if (undid) {
      redoState.push(this.state.chorale)
      redoState = _.reject(redoState, (val: any, i: number) => {
        return i > 0 && _.isEqual(redoState[i - 1], val);
      }).slice(-15);

      this.setState({ chorale: undid }, () => {
        this.validate()
      })
    }
  }

  redo() {

    let redid = redoState.pop();
    if (redid) {
      this.logUndo()

      this.setState({ chorale: redid }, () => {
        this.validate()
      })
    }
  }

  validate() {
    this.setState({ errors: this.state.chorale.validate() })
  }

  setChordIndex(pos: number, val: number) {
    this.setState({ chorale: this.state.chorale.setChordIndex(pos, val) }, () => {
      this.validate()
    })
  }
  playButton(pos: number) {
    this.state.chorale.playAudio(pos);
  }
  
  render() {
    return <Renderer
      measures={this.state.chorale.measures()}
      timeSignature={this.state.chorale.timeSignature}
      k={this.state.chorale.key}
      notes={this.state.chorale.voices}
      changeNotePitchAtPosition={this.changeNotePitchAtPosition.bind(this)}
      changeNoteDurationAtPosition={this.changeNoteDurationAtPosition.bind(this)}
      changeFiguredBassAtPosition={this.changeFiguredBassAtPosition.bind(this)}
      undo={this.undo.bind(this)}
      redo={this.redo.bind(this)}
      removeNote={this.removeNote.bind(this)}
      mouseUp={this.mouseUp.bind(this)}
      setNoteAccAtPosition={this.setNoteAccAtPosition.bind(this)}
      figuredBass={this.state.chorale.figuredBass}
      toggleAlwaysDisplayAccidentals={this.toggleAlwaysDisplayAccidentals.bind(this)}
      validate={this.validate.bind(this)}
      choraleErrors={this.state.errors}
      analyzeChord={this.state.chorale.analyzeChord.bind(this.state.chorale)}
      toggleNCT={this.toggleNCT.bind(this)}
      toggleDotted={this.toggleDotted.bind(this)}
      analyzeNCT={this.state.chorale.analyzeNCT.bind(this.state.chorale)}
      chordArray={this.state.chorale.chordArray.bind(this.state.chorale)}
      getChordIndex={this.state.chorale.getChordIndex.bind(this.state.chorale)}
      setChordIndex={this.setChordIndex.bind(this)}
      playButton={this.playButton.bind(this)}
      
    />
  }
}