import { Voice, VoiceRange } from './Voice'
import { RawNote } from './RawNote'
import { Note, TimeSignature, Interval, Chord,  Key, interval } from '@tonaljs/tonal';
import { FiguredBass } from '../render/Renderer';
import { RealNote } from './RealNote';
import { Config, calculateDistance, incrementNote, decrementNote  } from '../Util';
import {RealKey}  from './RealKey'
import { NCT } from './NonChordTone'
import AudioManager from './Audio';
export { NCT } from './NonChordTone'
export {RealKey} from './RealKey'
export { RawNote } from './RawNote';
export { RealNote } from './RealNote';
export { Voice } from './Voice';
var _ = require('lodash');

interface Figure {
  interval : number,
  alt : string,
  pitch: string
}

export class ChordResult {
  chord: string
  ncts: string[]

  constructor(chord: string, ncts: string[]) {
    this.chord = chord
    this.ncts = ncts
  }
}
enum MVMT {
  STEP = 0,
  LEAP,
  CT
}
export default class ImmutableChorale {

    voices : Voice[]
    key: RealKey;
    timeSignature: string
    figuredBass: FiguredBass
    recordUndo : () => void //callback
    ranges: VoiceRange[]
    pickup: number

    private positions: number[] = []
    private chordIndexes : number[] = []
    private audio : AudioManager
    constructor(voices: RawNote[][], 
                ranges: VoiceRange[], 
                key: RealKey, 
                timeSignature: string, 
                figuredBass: FiguredBass,
                pickup: number,
                recordUndo: () => void) {
            this.key = key
            let ts = TimeSignature.get(timeSignature)
            let division = ts.upper! as number/ts.lower!
            this.pickup = pickup

            var voiceIndex = 0;
            this.voices = voices.map((voice) => {
                
                var position = 0;
                let newNotes = voice.map((note) => {
                    let m1 = 0
                    if(position >= pickup && pickup != 0) {
                      m1 = 1
                    }
                    let realNote = new RealNote(note.note, note.tv, position, m1 + Math.floor((position-pickup) / division), voiceIndex, false, note.dotted, this.key)
                    if(this.positions.indexOf(position) == -1)
                      this.positions.push(position)
                    
                    this.positions = this.positions.sort();

        
                    position+= note.tv;
                    if(note.dotted) {
                      position+= (note.tv)/2
                    }
                    return realNote
                })
                let newVoice = new Voice(voiceIndex, newNotes, timeSignature, ranges[voiceIndex], key, this)
                voiceIndex++;

                  return newVoice;
            })
            this.timeSignature = timeSignature;
            this.figuredBass = figuredBass;
            this.recordUndo = recordUndo
            this.ranges = ranges
            this.audio = AudioManager.getInstance()
    }
    playAudio(position: number) {
      this.audio.play(this.voices, position)
    }
    stopAudio() {
      this.audio.stop()
    }
    getChordIndex(pos: number) : number {
      let obj = this.chordIndexes[pos]
      if(obj == undefined) return 0
      return obj
    }
    setChordIndex(pos: number, val: number) {
      this.chordIndexes[pos] = val
      return this
    }
    changeFiguredBassAtPos(time: number, val: string) {
        this.recordUndo() 
        this.figuredBass[time] = val
        
        return this;
    }
    measures() : number{
        return this.voices.sort((b,a) => {
            return a.measures() - b.measures()
        })[0].measures()
    }
    sortVoices() {
      this.voices = this.voices.slice().sort((a, b) => a.id - b.id)
      return this;
    }
    changeNotePitchAtPosition(voice : number, time: number, pitch: string, method: boolean) : ImmutableChorale {
      if(voice !== -1) {
        let notes = this.voices
      
        let p = pitch
        if(method !== true) {
          let nn = new RealNote(
              pitch, -1, time, -1, voice, false, false, this.key)
          nn.alt = 0
          p = nn.getPitch()
        }
        
      let height = Note.get(p).height! 


      let vPost = voice+1;
      let noteBelow = vPost >= notes.length  || !notes[vPost].index(time) ? undefined : notes[vPost].index(time);
      if(noteBelow) {
        let tNB = Note.get(noteBelow.getPitch())
        if(tNB && tNB.height! < height)
          return this;
        }
      let vPre = voice-1;
      let noteAbove = vPre < 0  || !notes[vPre].index(time) ? undefined : notes[vPre].index(time);
      if(noteAbove) {
        let tNA = Note.get(noteAbove.getPitch())
        if(tNA && tNA.height! > height)
          return this;
        }
      
      notes[voice] = notes[voice].changePitch(time, p) 
      
      this.voices = notes
        
      return this.sortVoices()
    }
    return this;
  }

  changeNoteDurationAtPosition(voice : number, time: number, duration: number) : ImmutableChorale {
    if(voice !== -1) {
      this.recordUndo() 

      let x = this
      let notes = x.voices

      notes[voice] = notes[voice].changeDuration(time, duration)
      
      if(notes[voice].index(time)?.dotted) {
        notes[voice] = notes[voice].toggleDotted(time)
      }     
      x.voices = notes
      return x.sortVoices()
    }
    return this
  }
  setNoteAccAtPosition(voice : number, time: number, delta: number) {
    if(voice !== -1) {
        this.recordUndo() 

        let x = this
        let notes = x.voices

        notes[voice] = notes[voice].setNoteAccAtPosition(time, delta)

        x.voices = notes
        return x.sortVoices()
    }
    return this
  }

  toggleExplicitlyDisplayAccidentals(voice : number, time: number) {
    if(voice !== -1) {
        this.recordUndo() 

        let x = this
        let notes = x.voices

        notes[voice] = notes[voice].toggleExplicitlyDisplayAccidentals(time)

        x.voices = notes
        return x.sortVoices()
    }
    return this
  }

  removeNote(voice: number, time: number) {
    if(voice !== -1) {
        this.recordUndo()
        let dur = this.voices[voice].index(time)?.getDuration()
        this.voices[voice] = this.voices[voice].removeNote(time)
        if(voice == 0 && dur) {
          return this.removeFiguredBass(time, dur).sortVoices()
        }
        return this.sortVoices()
    }
    return this
  }
  removeFiguredBass(time: number, duration: number) : ImmutableChorale {

    let newFB : FiguredBass = []
    for(const [key, value] of Object.entries(this.figuredBass)) {
      
      if(parseFloat(key) == time) continue;
      if(parseFloat(key) > time) {
        newFB[parseFloat(key) - duration] = value
      } else {
        newFB[parseFloat(key)] = value
      }
    }

    this.figuredBass = newFB
    return this
  }
  toggleNCT(voice: number, time: number) {
    if(voice !== -1) {
      this.recordUndo() 

      let x = this
      let notes = x.voices

      notes[voice] = notes[voice].toggleNonChordTone(time)

      x.voices = notes
      return x.sortVoices()
    }
    return this
    }
  toggleDotted(voice: number, time: number) {
    if(voice !== -1) {
      this.recordUndo() 

      let x = this
      let notes = x.voices

      notes[voice] = notes[voice].toggleDotted(time)

      x.voices = notes
      return x.sortVoices()
    }
    return this
    }
  
  seekPosition(index: number) : number {
    this.positions = this.positions.sort();
    if(this.positions.indexOf(index) == -1 || this.positions.indexOf(index)+1 >= this.positions.length) 
      return -1
    else return this.positions[this.positions.indexOf(index)+1]
  }

  private endsWeird = (str: string) => {
    return /[0-9]+$/.test(str) && !/[7]+$/.test(str)
  }


  /*
  1-14 work as numbers, everything higher than that splits

                                                64n  = 6
                                                      4n
                                                6/4n = 6/
                                                      4n
                                                /64n = 6
                                                      4n
                                                #64n =#6
                                                      4n
                                                6##4n=6#
                                                      4#
                                                6,#4 =6
                                                    #4


  */
  
  private getFigures(pos: number) : Figure[] {

    let fb = this.figuredBass[pos]
    let bassNote = this.voices[0].index(pos)
    
    if(!bassNote)
      return []
    
    if(!fb || fb == "") { //3rd and 5th
      return []
    }
  
    var x = 0;
    let line = 0;

    let num = -1
    let sym : string = ""
    let figures = []
    
    while(x<fb.length) {
      let chunk = fb.substring(x, fb.length);
      if(chunk.charAt(0) == "/" || chunk.charAt(0) == "#" || chunk.charAt(0) == "b" || chunk.charAt(0) == "n" || chunk.charAt(0) == "x" || chunk.charAt(0) == "-") {
        if(num != -1 || x == fb.length-1) {
          sym = chunk.charAt(0)
          if(num == -1) {
            num = 3;
            sym = chunk.charAt(0)
          }

          var pitch : string = ""
          if(sym == "-") {
            if(Object.keys(this.figuredBass).indexOf(pos.toString()) - 1 >= 0) {
              let prevPos = Object.keys(this.figuredBass).map(x=>parseFloat(x)).sort()[Object.keys(this.figuredBass).map(x=>parseFloat(x)).sort().indexOf(pos) - 1]
              if(!isNaN(prevPos) && this.getFigures(prevPos)[x-1]) {
                pitch = this.getFigures(prevPos)[x-1].pitch
              }
            }
          } else {
              pitch = this.applyFB(bassNote.getPitch(), num, sym)
          }

          figures.push({
            interval : sym == "-" ? -1 : num,
            alt: sym,
            pitch: pitch
          })

          line++;
          num = -1;
          sym = ""
        } else {
          sym = chunk.charAt(0)
        }
      }  
      else if(chunk.charAt(0) == ",") {
          if(num != -1) {
            var pitch : string = ""
            if(sym == "-") {
              if(Object.keys(this.figuredBass).indexOf(pos.toString()) - 1 >= 0) {
                let prevPos = Object.keys(this.figuredBass).map(x=>parseFloat(x)).sort()[Object.keys(this.figuredBass).map(x=>parseFloat(x)).sort().indexOf(pos) - 1]
                if(!isNaN(prevPos) && this.getFigures(prevPos)[x-1]) {
                  pitch = this.getFigures(prevPos)[x-1].pitch
                }
              }
            } else {
                pitch = this.applyFB(bassNote.getPitch(), num, sym)
            }
  
            figures.push({
              interval : sym == "-" ? -1 : num,
              alt: sym,
              pitch: pitch
            })

            line++;
            num = -1
            sym = ""
          }
      } else {
        if(sym != "") {
          num = parseInt(chunk.split(/[\/#bnx,]/)[0])
          if(num > 14) {
            num = parseInt(chunk.charAt(0))
          } else {
            x+= chunk.split(/[\/#bnx,]/)[0].length-1
          }

          var pitch : string = ""
          if(sym == "-") {
            if(Object.keys(this.figuredBass).indexOf(pos.toString()) - 1 >= 0) {
              let prevPos = Object.keys(this.figuredBass).map(x=>parseFloat(x)).sort()[Object.keys(this.figuredBass).map(x=>parseFloat(x)).sort().indexOf(pos) - 1]
              if(!isNaN(prevPos) && this.getFigures(prevPos)[x-1]) {
                pitch = this.getFigures(prevPos)[x-1].pitch
              }
            }
          } else {
              pitch = this.applyFB(bassNote.getPitch(), num, sym)
          }

          figures.push({
            interval : sym == "-" ? -1 : num,
            alt: sym,
            pitch: pitch
          })

          line++;
          num = -1
          sym = ""
        } else {
          let prevNum = num
        
          num = parseInt(chunk.split(/[\/#bnx,]/)[0])
          if(num > 14) {
            num = parseInt(chunk.charAt(0))
          } else {
            x+= chunk.split(/[\/#bnx,]/)[0].length-1
          }

          if(prevNum == -1  && !chunk.charAt(1).match((/[\/#bnx,]/))) {    
              figures.push({
                interval : num,
                alt: sym,
                pitch: this.applyFB(bassNote.getPitch(), num, sym)
              })

              line++;
              num = -1;
              sym = ""
          }
        }
      }
      x++;
    }

    return figures
  }

  applyFB(bass: string, interval: number, symbol: string) : string {
    let newNote = new RealNote(incrementNote(bass, interval-1),0,0,0,0,false, false, this.key)
    newNote.alt = 0

    if(symbol == "n") {
      symbol = "♮"
    }
    if(symbol == "/") {
      newNote.alt += 1;

      if(newNote.letter == bass.charAt(0))
        return bass

      return newNote.letter + newNote.getAccidentals()
    } else {
      return newNote.letter + (symbol == "" ? newNote.getAccidentals() : symbol) //ignore ks, put x in front of the note
    }
  }

  analyzeChord(pos: number, method: boolean, ignoreNCT: boolean = false, skipRabbitHole : boolean = false) : string {
    let bassNote = this.voices[0].index(pos)
    
    if(!bassNote)
      return ""
    
    var possibleChords : string[] = []

    let pitches = this.getFigures(pos).map((x) => x.pitch)
    pitches.push(bassNote.getPitch().replace(/[0-9]/g, ''))

    let intervals = this.getFigures(pos).map((x) => x.interval)

    if(!method) {  // 1 == from notes
      let notes = this.voices.flatMap((v) => {
        let note = v.index(pos)
        return note && (!note.isNonChordTone || ignoreNCT) ? [note.getPitch()] : []
      })

      possibleChords = Chord.detect(notes)

      if(intervals.includes(6) && !intervals.includes(4) && !intervals.includes(3)) { // first inversion
        notes = notes.sort((a,b) => {
          return Note.get(a).height!-Note.get(b).height!
        })
        let root = decrementNote(notes[0].charAt(0),2).charAt(0)
        
        possibleChords = possibleChords.filter((i) => {
          return i.startsWith(root)
        })
      }
  
    } else { // 0 = from figured bass
      //https://robertkelleyphd.com/home/figured-bass/
      if(!(intervals.indexOf(2) != -1 || intervals.indexOf(4) != -1)) {  //add 3 above the bass
        let pitch = this.applyFB(bassNote.getPitch(), 3, "")
        if(intervals.indexOf(3) == -1 && pitches.indexOf(pitch) == -1) {
          pitches.push(pitch)
          intervals.push(3)
        }
      }
      if(!((intervals.indexOf(4) != -1 && (intervals.indexOf(3) != -1  || intervals.indexOf(2) != -1)) || intervals.indexOf(6)  != -1)) { // add 5 above bass
        let pitch = this.applyFB(bassNote.getPitch(), 5, "")
        if(intervals.indexOf(5) == -1 && pitches.indexOf(pitch) == -1) {
          pitches.push(pitch)
          intervals.push(5)
        }
      }
      if((intervals.indexOf(3) != -1 || intervals.indexOf(2) != -1) && intervals.indexOf(4) != -1 && intervals.indexOf(6) == -1) { //add 6 above bass
        let pitch = this.applyFB(bassNote.getPitch(), 6, "")
        if(intervals.indexOf(6) == -1 && pitches.indexOf(pitch) == -1) {
          pitches.push(pitch)
          intervals.push(6)
        }
      }

      let notes = pitches.map((note) => {
        return note.replaceAll("♮","")
      })
      possibleChords = Chord.detect(notes)
      
      if(intervals.includes(6) && !intervals.includes(4) && !intervals.includes(3)) { // first inversion

        let rootNote = this.sortVoices().voices[0].index(pos)
        let root = decrementNote(rootNote!.getPitch().charAt(0),2).charAt(0)
           
        possibleChords = possibleChords.filter((i) => {
          return i.startsWith(root)
        })
      }
    }

    let chord = this.sanitizeChord(possibleChords)

    if(chord != "")
      return chord
    

    //try without a 9th 
    if(intervals.includes(9)) {
      pitches = pitches.filter(x => x != this.getFigures(pos).find((x) => x.interval == 9)!.pitch)
    }
    let notes = pitches.map((note) => {
      return note.replaceAll("♮","")
    })
    possibleChords = Chord.detect(notes)

    chord = this.sanitizeChord(possibleChords)

    if(chord != "")
      return chord
    
    if(method) {
      //without 2 
      
      //without 4

      //finally, just look for any interval of a third in the chord, and use those to make a 1 3 5, worst case.

      possibleChords = []

      for(var i of pitches) {
        for(var j of pitches) {
          if(Interval.get(Interval.distance(i,j)).num == 3) {
            let fifth = Note.transpose(i, "5P");

            possibleChords = possibleChords.concat(Chord.detect([i,j,fifth]))
          }
        }
      }

      for(var i of pitches) {
        for(var j of pitches) {
          if(Interval.get(Interval.distance(i,j)).num == 5) {
            let third = this.applyFB(i +"1", 3, "")

            possibleChords = possibleChords.concat(Chord.detect([i,j,third]))
          }
        }
      }
    
      if(!skipRabbitHole) {
        if(Object.keys(this.figuredBass).indexOf(pos.toString()) - 1 >= 0) {
          let prevPos = parseFloat(Object.keys(this.figuredBass)[Object.keys(this.figuredBass).indexOf(pos.toString()) - 1])
          if(!isNaN(prevPos)) {
            let prevChord = this.analyzeChord(prevPos, false, false, true)
            if(possibleChords.map(x=>x.toLowerCase()).indexOf(prevChord.toLowerCase()) != -1)
              return prevChord
          }
        }
        if(Object.keys(this.figuredBass).indexOf(pos.toString()) + 1 < Object.keys(this.figuredBass).length) {
          let nextPos = parseFloat(Object.keys(this.figuredBass)[Object.keys(this.figuredBass).indexOf(pos.toString()) + 1])
          if(!isNaN(nextPos)) {
            let nextChord = this.analyzeChord(nextPos, false, false, true)
            if(possibleChords.map(x=>x.toLowerCase()).indexOf(nextChord.toLowerCase()) != -1)
              return nextChord
          }
        }
      }

      chord = this.sanitizeChord(possibleChords)
      if(chord != "")
        return chord
    }
    return ""
  }

  sanitizeChord = (possibleChords : string[]) => {
    var x = 0
    while(possibleChords.length > x) {
      let crd = possibleChords[x].split("/")[0]
      crd = crd.replace("dim","°")
      if(crd.endsWith("M")) {
        crd = crd.substring(0, crd.length-1)
      }
      if(crd.endsWith("m7b5")) {
        crd = crd.replace("m7b5","ø7")
      }
      if(crd.indexOf("add")!=-1 || crd.indexOf("sus") != -1 || this.endsWeird(crd)) {
        x+=1
      } else return crd
    }
    return ""
  }

  chordArray(pos: number) : ChordResult[] {
    let bassNote = this.voices[0].index(pos)
    
    if(!bassNote)
      return []
    
    var possibleChords : string[] = []

    let pitches = this.getFigures(pos).map((x) => x.pitch)
    pitches.push(bassNote.getPitch().replace(/[0-9]/g, ''))

    let intervals = this.getFigures(pos).map((x) => x.interval)

    //https://robertkelleyphd.com/home/figured-bass/
    if(!(intervals.indexOf(2) != -1 || intervals.indexOf(4) != -1)) {  //add 3 above the bass
      let pitch = this.applyFB(bassNote.getPitch(), 3, "")
      if(intervals.indexOf(3) == -1 && pitches.indexOf(pitch) == -1) {
        pitches.push(pitch)
        intervals.push(3)
      }
    }
    if(!((intervals.indexOf(4) != -1 && (intervals.indexOf(3) != -1  || intervals.indexOf(2) != -1)) || intervals.indexOf(6)  != -1)) { // add 5 above bass
      let pitch = this.applyFB(bassNote.getPitch(), 5, "")
      if(intervals.indexOf(5) == -1 && pitches.indexOf(pitch) == -1) {
        pitches.push(pitch)
        intervals.push(5)
      }
    }
    if((intervals.indexOf(3) != -1 || intervals.indexOf(2) != -1) && intervals.indexOf(4) != -1 && intervals.indexOf(6) == -1) { //add 6 above bass
      let pitch = this.applyFB(bassNote.getPitch(), 6, "")
      if(intervals.indexOf(6) == -1 && pitches.indexOf(pitch) == -1) {
        pitches.push(pitch)
        intervals.push(6)
      }
    }

    let chordNotes = pitches.map((note) => {
      return note.replaceAll("♮","")
    })
    possibleChords = Chord.detect(chordNotes)
    
    if(intervals.includes(6) && !intervals.includes(4) && !intervals.includes(3)) { // first inversion

      let rootNote = this.sortVoices().voices[0].index(pos)
      let root = decrementNote(rootNote!.getPitch().charAt(0),2).charAt(0)
          
      possibleChords = possibleChords.filter((i) => {
        return i.startsWith(root)
      })
    }

    let chord = this.sanitizeChord(possibleChords)
    
    //try without a 9th 
    if(intervals.includes(9)) {
      pitches = pitches.filter(x => x != this.getFigures(pos).find((x) => x.interval == 9)!.pitch)
    }
    let notes = pitches.map((note) => {
      return note.replaceAll("♮","")
    })
    possibleChords = Chord.detect(notes)

    //without 2 
    
    //without 4

    //finally, just look for any interval of a third in the chord, and use those to make a 1 3 5, worst case.
   let strangeChords : ChordResult[] = []
   
    for(var i of pitches) {
      for(var j of pitches) {
        if(Interval.get(Interval.distance(i,j)).num == 3) {
          let fifth = Note.transpose(i, "5P");

          let crd = Chord.detect([i,j,fifth])
          strangeChords.concat(crd.map(possibleChord => { 
            return new ChordResult(possibleChord, pitches.filter((p)=> !(p==i || p == j)))
          }))
        }
      }
    }

    for(var i of pitches) {
      for(var j of pitches) {
        if(Interval.get(Interval.distance(i,j)).num == 5) {
          let third = this.applyFB(i +"1", 3, "")

          let crd = Chord.detect([i,j,third])
          strangeChords.concat(crd.map(possibleChord => { 
            return new ChordResult(possibleChord, pitches.filter((p)=> !(p==i || p == j)))
          }))
        }
      }
    }
    
    //whatever
    if(Object.keys(this.figuredBass).indexOf(pos.toString()) - 1 >= 0) {
      let prevPos = parseFloat(Object.keys(this.figuredBass)[Object.keys(this.figuredBass).indexOf(pos.toString()) - 1])
      if(!isNaN(prevPos)) {
        let prevChord = this.analyzeChord(prevPos, false, false, true)
        if(possibleChords.map(x=>x.toLowerCase()).indexOf(prevChord.toLowerCase()) != -1) {
          possibleChords.push(prevChord)
        }
      }
    }
    if(Object.keys(this.figuredBass).indexOf(pos.toString()) + 1 < Object.keys(this.figuredBass).length) {
      let nextPos = parseFloat(Object.keys(this.figuredBass)[Object.keys(this.figuredBass).indexOf(pos.toString()) + 1])
      if(!isNaN(nextPos)) {
        let nextChord = this.analyzeChord(nextPos, false, false, true)
        if(possibleChords.map(x=>x.toLowerCase()).indexOf(nextChord.toLowerCase()) != -1)
          possibleChords.push(nextChord)
      }
    }
    

    possibleChords = this.sanitizeChords(possibleChords)

    return possibleChords.map((possibleChord) => {
      return new ChordResult(possibleChord, [])
    }).concat(strangeChords)
  }

  sanitizeChords = (possibleChords : string[]) => {
    var x = 0
    var newChords = []
    while(possibleChords.length > x) {
      let crd = possibleChords[x].split("/")[0]
      crd = crd.replace("dim","°")
      if(crd.endsWith("M")) {
        crd = crd.substring(0, crd.length-1)
      }
      if(crd.endsWith("m7b5")) {
        crd = crd.replace("m7b5","ø7")
      }
      if(crd.indexOf("add")!=-1 || crd.indexOf("sus") != -1 || this.endsWeird(crd)) {
        x+=1
      } else {
        newChords.push(crd)
        x++;
      }
    }
    return newChords
  }
  // f b d a
  // e b g
  // f d a
  // d f a
  // e f g -- what do we do here?
  // e b
  // d g a
  sortInThirds(pitches : string[]) {
    pitches.sort() // f d a = a d f
    
  }
  
  analyzeNCT(voice: number, pos: number) : NCT {
    let v = this.voices[voice]

    let notes = v.sorted()

    let note = v.index(pos)
    if(!note) return NCT.NCT
    
    let i = notes.indexOf(note);
    
    let noteBefore = i-1 >= 0 ? notes[i-1] : undefined
    let noteAfter = i+1 < notes.length ? notes[i+1] : undefined

    let approachInterval = noteBefore ? Interval.get(Interval.distance(noteBefore.getPitch(), note.getPitch())) : undefined

    let resolutionInterval = noteAfter ? Interval.get(Interval.distance(note.getPitch(), noteAfter.getPitch())) : undefined
    

    var aInterval = 0
    var bInterval = 0

    if(approachInterval && resolutionInterval) {
      aInterval = Math.abs(approachInterval.num!)
      let approach = aInterval == 1 ? MVMT.CT : aInterval == 2 ? MVMT.STEP : MVMT.LEAP

      bInterval = Math.abs(resolutionInterval.num!)
      let resolution = bInterval == 1 ? MVMT.CT :  bInterval == 2 ? MVMT.STEP : MVMT.LEAP

      if(approach == MVMT.STEP && resolution == MVMT.STEP) {
        if(approachInterval.dir == resolutionInterval.dir) {
          return NCT.PT
        } else {
          return NCT.NT
        }
      } else if(approach == MVMT.LEAP && resolution == MVMT.STEP && approachInterval.dir != resolutionInterval.dir) {
        return NCT.APP
      } else if(approach == MVMT.STEP && resolution == MVMT.LEAP && approachInterval.dir != resolutionInterval.dir) {
        return NCT.ET
      } else if(approach == MVMT.CT && resolution == MVMT.STEP && resolutionInterval.dir == -1) {
        return NCT.SUS
      } else if(approach == MVMT.CT && resolution == MVMT.STEP && resolutionInterval.dir == 1) {
        return NCT.RET
      } else if(approach == MVMT.CT && resolution == MVMT.CT) {
        return NCT.PED
      } else if(resolution == MVMT.CT) {
        return NCT.ANT
      }
    } else if(resolutionInterval) {
      bInterval = Math.abs(resolutionInterval.num!)
      let resolution = bInterval == 1 ? MVMT.CT :  bInterval == 2 ? MVMT.STEP : MVMT.LEAP
      
      if(resolution == MVMT.CT) {
        return NCT.ANT
      }
    } 
    return NCT.NCT
  }

  validate() : ChoraleError[] {
    let errors : ChoraleError[] = []
        
    //check each voice for augmented 2nds, tritones
    for(var voice of this.voices) {
      let notes = voice.notes.sort((a,b)=> {
        return a.position - b.position
      })
        for(var i = 0; i< notes.length; i++) {

          let noteA = notes[i]

          //if there is a next note
          if(i < notes.length-1) {
            let interval = Interval.get(Interval.distance(noteA.getPitch(), notes[i+1].getPitch()))
            if((interval.q === "A" &&(Math.abs(interval.num!) == 2 || Math.abs(interval.num!)==4))) {
                if(Math.abs(calculateDistance(noteA.getPitch(), notes[i+1].getPitch())) == 1
                || Math.abs(calculateDistance(noteA.getPitch(), notes[i+1].getPitch())) == 3) {
                  errors.push(
                    new ChoraleError(ChoraleErrorType.augmented, 
                        interval.num!, 
                        [notes[i].voice], 
                        noteA.getStart(),
                        notes[i+1].getStart()))
                }
            }
            //leaaps

            if(voice.id != 0 && Math.abs(interval.num!) > Config.maxLeap) {
              errors.push(
                new ChoraleError(ChoraleErrorType.leap, 
                    interval.num!, 
                    [notes[i].voice], 
                    noteA.getStart(),
                    notes[i+1].getStart()))
            } 

            
            //parallels

            let nextNotePos = voice.seekPosition(noteA.getStart()) //get next position, not next note in voice...

            if(nextNotePos != -1) {
              let samePosition = this.voices.filter((vox) => vox.id != noteA.voice)
              .map((vox) => {
                let posStart = noteA.getStart()
                return vox.index(posStart);
              }).filter((x) => x !== undefined) as RealNote[]

              samePosition.flatMap((noteB) => {
                let interval = Interval.get(Interval.distance(noteA.getPitch(), noteB!.getPitch()))
                if(interval.q == "P" && (interval.num! == 8 || interval.num! == 5)) {
                  let nextNoteA = this.voices[noteA.voice].index(nextNotePos)
                  let nextNoteB = this.voices[noteB.voice].index(nextNotePos)
                  if(!nextNoteA || !nextNoteB)
                    return
                  let interval2 = Interval.get(Interval.distance(nextNoteA.getPitch(), nextNoteB!.getPitch()))
                  if(interval.q == interval2.q && interval.type == interval2.type && interval.num == interval2.num && nextNoteA.getPitch() != noteA.getPitch() && nextNoteB.getPitch() != noteB.getPitch())  {
                    errors.push(
                      new ChoraleError(ChoraleErrorType.parallel, interval.num, [noteA.voice, noteB.voice], noteA.getStart(), nextNoteA.getStart())
                    )
                  }
                }
              })
            }
          }
        }

    }
    
    for(var x in this.positions) {
      let pos = this.positions[x]
      let crd = this.analyzeChord(pos, false, true);
      if(crd.indexOf("7")!=-1){
        let rootLetter = crd.charAt(0)
        let sve = decrementNote(rootLetter,1).charAt(0)
        let down = decrementNote(sve,1).charAt(0)
        let up = incrementNote(sve,1).charAt(0)

        let voiceId = null;
        for(var v in this.voices) {
          let voice = this.voices[v]
          let note = voice.index(pos)
          if(note && note.getPitch().startsWith(sve)) { // found voice

            voiceId = v
            break;
          }
        }

        if(!voiceId)
          continue;
          
        let voice = this.voices[parseInt(voiceId)]

        let note = voice.index(pos)! 
        //check next to see if 7 resolves down
        if(parseInt(x) < this.positions.length-1) {
          let next = this.positions[parseInt(x)+1]
          
          let nextNote = voice.index(next)
          if(nextNote && !nextNote!.getPitch().startsWith(down) && 
              nextNote.getPitch().charAt(0) != note.getPitch().charAt(0)) {
            console.log("7th error, "+sve+" should go to "+down + " goes to " + nextNote.getPitch().charAt(0))
            let interval = Interval.get(Interval.distance(note.getPitch(), nextNote!.getPitch()))!.num!
            errors.push(
              new ChoraleError(ChoraleErrorType.seventh, interval, [voice.id], pos, nextNote.getStart())
            )
          }
        }
        if(parseInt(x)!==0) {
          let prev = this.positions[parseInt(x)-1]
          let prevNote = voice.index(prev)
          if(prevNote && !prevNote!.getPitch().startsWith(up) && 
          prevNote.getPitch().charAt(0) != note.getPitch().charAt(0) 
          && !prevNote!.getPitch().startsWith(down))  {
            console.log("7th approach error, "+sve+" should be approached by step but is approached by " + prevNote.getPitch().charAt(0))
            let interval = Interval.get(Interval.distance(note.getPitch(), prevNote!.getPitch()))!.num!
            errors.push(
              new ChoraleError(ChoraleErrorType.seventh, interval, [voice.id], prevNote.getStart(), pos)
            )
          }
        }

      }


    }
    return errors;
  }
}


export enum ChoraleErrorType {
    augmented = 1,
    parallel,
    leap,
    seventh
  }

export class ChoraleError {
    errorType: ChoraleErrorType
    voices: number[]
    interval: number
    posStart: number
    posEnd: number

    constructor(errorType: ChoraleErrorType, interval: number, voices: number[], posStart: number, posEnd: number) {
        this.errorType = errorType
        this.interval = interval
        this.voices = voices
        this.posStart = posStart
        this.posEnd = posEnd
    }
    
    
}