
export enum NCT {
    NCT = 0,
    PT,
    NT,
    APP,
    ET,
    CT,
    ANT,
    SUS,
    RET,
    PED,
  }
