import { TimeSignature, Key } from "@tonaljs/tonal";
import { Config, incrementNote, calculateLedgerLines, bedNumberForNote, convertTimeSignatureChar } from "../Util"
import {Motion, spring} from 'react-motion';
import RendererNote from "./RendererNote"
import {RealKey} from "../engine/Engine"

let bedsPerStaff = (Config.linesPerStaff*2)-1;

export interface StaffProperties {
    staff: number,
    staffWidth: number,
    staffMarginY: number,
    isFirst: boolean, //is first staff?
    clef: string,
    firstNote: string,
    lastNote: string,
    timeSignature: string,
    k: RealKey,
    scale: number,
    mousePressY: number,
    mouseCurrentY: number,
    notes: RendererNote[][],
    gutters: RendererNote[][],
    setRef: (x: number, el: HTMLDivElement) => void
}

export default function Staff(staffProperties: StaffProperties) {
    let ts = TimeSignature.get(staffProperties.timeSignature)

    
    return  <div className="staff py-5" 
    style={{
        width: staffProperties.staffWidth,
        marginTop: !staffProperties.isFirst ? staffProperties.staffMarginY : 0
    }}
    >
            <h1 className="clef">{staffProperties.clef}</h1>

            <div className="key-signature">
                {
                    buildKeySignature(staffProperties.k.letters[0], 
                                    parseInt(staffProperties.lastNote.substring(staffProperties.lastNote.length-1, 
                                                                                staffProperties.lastNote.length)), 
                                                                                staffProperties.k.letters.length, 
                                    staffProperties.staff)
                    .map((note, i) => {
                            let bedNum = bedNumberForNote(note.charAt(0), parseInt(note.charAt(1)), staffProperties.staff)
                            if(bedNum < 0 && staffProperties.staff === 1) {
                                bedNum=bedsPerStaff*(staffProperties.staff+1)
                            } 
                            let top = (bedNum - bedsPerStaff*staffProperties.staff )* Config.bedHeight;

                            return <div className="key-sig-item" style={{
                                top: top + "px",
                                left: Config.leftOffset + (i*Config.qNSpacing*Config.ksSpread) + "px"
                            }} key={i}>
                                {staffProperties.k.sig.charAt(0) === "#" ? "♯" : "♭"}
                                </div>
                    })

                }
            </div>
            <div className="time-signature" style={{left: Config.leftOffset + (staffProperties.k.letters.length*Config.qNSpacing*Config.ksSpread) + 10}}>
                <div className="ts-upper">{ts.upper && convertTimeSignatureChar(ts.upper as number)}</div>
                <div className="ts-lower">{ts.lower && convertTimeSignatureChar(ts.lower as number)}</div>
            </div>
            <div className="gutter-top">
                {staffProperties.gutters && staffProperties.gutters[1] && staffProperties.gutters[1].map((noteE) => {
                    if(noteE) {
                        return <Motion key={noteE.note.voice+"-"+noteE.note.position} style={{verticalOffset: spring(staffProperties.mouseCurrentY - staffProperties.mousePressY, { stiffness: Config.noteStiffness, damping: Config.noteDamping })}}> 
                        
                        {({verticalOffset}) =>
                        {
                            return <>{noteE.getElement(staffProperties.scale, verticalOffset)}
                            {[...Array(Math.floor((noteE.ledgerLinesRequired) / 2))].map((_, i) =>                                             
                                    <div className="ll" key={i} style={{position: "absolute",
                                    top: - 10 - Config.bedHeight*2*i + "px", left: noteE.documentX*staffProperties.scale + noteE.marginX}}>
                                        <hr></hr>
                                    </div>
                                )}
                                </>
                            }

                        }
                        </Motion>
                    } return null
                })}
            </div>
            {Array.from(Array(bedsPerStaff), (_, note) =>
            <Motion key={note} style={{verticalOffset: spring(staffProperties.mouseCurrentY - staffProperties.mousePressY, { stiffness: Config.noteStiffness, damping: Config.noteDamping })}}> 
                        
            {({verticalOffset}) =>
            {
        return <div className={"bed " + (note % 2 === 0 && "bed-line")} style={{height: Config.bedHeight}} id={getNote(staffProperties.staff, note)} ref={el => {
                if (!el) return;
                    staffProperties.setRef((staffProperties.staff)*bedsPerStaff + note, el)
                }} >
                { staffProperties.notes[note] && staffProperties.notes[note].map((noteE) => {
                    if(noteE)
                        return noteE.getElement(staffProperties.scale, verticalOffset)
                    return null
                })}
                {note % 2 === 0 && <hr className="line my-0">

                </hr>}
            </div>
                }
            }
            </Motion>)}

            <div className="gutter-bot">
                {staffProperties.gutters && staffProperties.gutters[0] && staffProperties.gutters[0].map((noteE) => {
                        if(noteE) {
                            return  <Motion key={noteE.note.voice+"-"+noteE.note.position} style={{verticalOffset: spring(staffProperties.mouseCurrentY - staffProperties.mousePressY, { stiffness: Config.noteStiffness, damping: Config.noteDamping })}}> 
                        
                        {({verticalOffset}) =>
                        {
                            return  <> {noteE.getElement(staffProperties.scale, verticalOffset)}
                                {[...Array(Math.floor((noteE.ledgerLinesRequired*-1) / 2))].map((_, i) =>                                             
                                    <div className="ll" key={i} style={{position: "absolute",
                                    top: -35+ Config.bedHeight*2*i + "px", left: noteE.documentX*staffProperties.scale + noteE.marginX}}>
                                        <hr></hr>
                                    </div>
                                )}
                                </>
                        }

                    }
                </Motion>
             } return null
            })}
            </div>
</div>
}

//f, 5, 2
function buildKeySignature(firstLetter: string, startingOct: number, amt: number, staff: number) {
    return [...Array(amt)].map((_, i) => {
        let note = incrementNote(firstLetter+startingOct, firstLetter === "F" ? 4*i : 3*i)
        let o = parseInt(note.substring(1,2))
        while(Math.abs(calculateLedgerLines(
            note.charAt(0), o, staff)) > 1 
            || (
                (note.charAt(0) === "A" 
                || note.charAt(0) === "B" 
                || (note.charAt(0) === "G" && firstLetter === "B"))
                && o===3) 
            || (note.charAt(0) === "G" 
                && firstLetter === "B" 
                && o === 5
                )
            || (note.charAt(0) === "F" 
                && firstLetter === "B" 
                && (o === 5 || o === 3))
        )
            o -= 1;
        return note.charAt(0)+o
    }) 
}

function getNote(s: number, n: number) {
    let base = Config.startingNotes[s]
    return incrementNote(base, bedsPerStaff - n - 1)
}
