
import { Key } from '@tonaljs/tonal';
import { Config } from '../Util';

export class RealKey {

    key: string;
    letters: string[]
    sig: string

    constructor(key: string) {
        this.key = key
        this.sig = key.indexOf("m") !==-1 ? Key.minorKey(key.substring(0,key.length-1)).keySignature : Key.majorKey(key).keySignature

        this.letters = this.sig.charAt(0) === "#" ? 
            [...Array(this.sig.length)].map((_, i) => {
                return Config.allLetters[(Config.allLetters.indexOf("F") + i*4) % 7]
            }) : 
            [...Array(this.sig.length)].map((_, i) => {
                return Config.allLetters[(Config.allLetters.indexOf("B") + i*3) % 7]
            }) 
    }
   
}